import object from "@pag/utils/object-tools"
import {
  INIT_CONNECT_DEVICE,
  CANCEL_CONNECT_DEVICE,
  CONNECT_DEVICE,
  REMOVE_DEVICE,
  SET_PREFERRED_DEVICE,
  SET_ACTIVE_DEVICE,
  TURN_DEVICE_SERVICE_ON,
  TURN_DEVICE_SERVICE_OFF,
  SET_ARRIVED_FROM_STATUS_BAR,
  
  Device,
  Service,
  ServiceState,

  State,
  Action

} from "./types"
import data from "./data"

const [
  js_phone,
  mb_phone
] = data.devices

const [
  phone,
  media,
  messages,
  carplay,
  android_auto,
  ipod
] = data.services

const initialState: State = {

  devices: data.devices.slice(),

  preferred: undefined,

  active: js_phone,

  // @NOTE(kirill): This array defines the order of services in the header.
  services: object.populate(
      "service",    "devices"
  )(
    [ phone,        [ mb_phone, js_phone ] ],
    [ media,        [ js_phone ]           ],
    [ messages,     [ js_phone, mb_phone ] ],
    [ carplay,      [ js_phone ]           ],
    [ android_auto, []                     ],
    [ ipod,         []                     ]
  ),

  connecting: undefined,
  
  back: false
}

function turnServiceOff(old_services: ServiceState[], service: Service): ServiceState[] {
  const services = old_services.slice()

  const index = services.findIndex((state: ServiceState) => state.service === service)
  const service_state = services[index]

  const new_service_state = Object.assign(
    {},
    service_state,
    { devices: Object.freeze([]) }
  )

  services.splice(index, 1, new_service_state)

  return services
}

function disconnect_device_from_a_service(service: ServiceState, device: Device): ServiceState {
  if (service.devices.includes(device)) {
    return Object.freeze(Object.assign(
      Object.create(null),
      service,
      { devices: service.devices.filter((candidate: Device) => candidate !== device) }
    ))
  } else {
    return service
  }
}

function isServiceActive(state: State, device: Device, service: Service): boolean {
  const service_state = state.services.find((service_state: ServiceState) => service_state.service === service)
  return (
    service_state !== undefined
    ? service_state.devices.includes(device)
    : false
  )
}

export default Object.freeze(function deviceReducer(state: State = initialState, action: Action): State {
  switch (action.type) {
  
  case REMOVE_DEVICE: {
    const new_services = state.services.map((service: ServiceState) => disconnect_device_from_a_service(service, action.device))
    const connected_to_phone_service = isServiceActive(state, action.device, phone)
    let active = state.active
    if (connected_to_phone_service && state.active === action.device) {
      const phone_service = new_services.find((service_state: ServiceState) => service_state.service === phone)
      if (phone_service !== undefined && phone_service.devices.length > 0) {
        active = phone_service.devices[0]
      }
    }

    return Object.freeze({
      ...state,
      devices: state.devices.filter((candidate: Device) => candidate !== action.device),
      preferred: state.preferred === action.device ? undefined : state.preferred,
      active,
      services: new_services
    })
  }

  case INIT_CONNECT_DEVICE: {
    return Object.freeze({
      ...state,
      connecting: action.service
    })
  }

  case CANCEL_CONNECT_DEVICE: {
    return Object.freeze({
      ...state,
      connecting: undefined
    })
  }

  case CONNECT_DEVICE: {
    let services = state.services.slice()

    if (
      state.connecting === data.carplay_service ||
      state.connecting === data.android_auto_service
    ) {
      services = turnServiceOff(
        turnServiceOff(services, data.carplay_service),
        data.android_auto_service
      )
    }

    const index = services.findIndex((service: ServiceState) => service.service === state.connecting)
    const service_state = services[index]
    const new_service_state = Object.assign(
      {},
      service_state,
      { devices: Object.freeze(service_state.devices.concat([ action.device ])) }
    )
    services.splice(index, 1, new_service_state)

    const devices = (
      state.devices.includes(action.device)
      ? state.devices.slice()
      : state.devices.concat([ action.device ])
    )

    return Object.freeze({
      ...state,
      services: Object.freeze(services),
      devices: Object.freeze(devices),
      connecting: undefined
    })
  }

  case SET_PREFERRED_DEVICE: {
    return {
      ...state,
      preferred: action.device,
      active: (
        action.device === undefined
        ? state.active
        : (
          isServiceActive(state, action.device, data.phone_service)
          ? action.device
          : state.active
        )
      )
    }
  }

  case SET_ACTIVE_DEVICE: {
    return {
      ...state,
      active: action.device
    }
  }

  case TURN_DEVICE_SERVICE_ON: {
    let services = state.services.slice()
    if (
      action.service === data.carplay_service ||
      action.service === data.android_auto_service
    ) {
      services = turnServiceOff(
        turnServiceOff(services, data.carplay_service),
        data.android_auto_service
      )
    }

    const index = services.findIndex((service: ServiceState) => service.service === action.service)
    const service_state = services[index]
    const new_service_state = Object.assign(
      {},
      service_state,
      { devices: Object.freeze(service_state.devices.concat([ action.device ])) }
    )

    services.splice(index, 1, new_service_state)

    return Object.freeze({
      ...state,
      services: Object.freeze(services),
      active: (
        action.service === data.phone_service
        ? (
          (
            state.preferred !== undefined &&
            new_service_state.devices.includes(state.preferred)
          )
          ? state.preferred
          : new_service_state.devices[new_service_state.devices.length - 1]
        )
        : state.active
      )
    })
  }

  case TURN_DEVICE_SERVICE_OFF: {
    const services = state.services.slice()
    const index = services.findIndex((service: ServiceState) => service.service === action.service)
    const service_state = services[index]
    const new_service_state = Object.assign(
      {},
      service_state,
      {
        devices: Object.freeze(
          service_state.devices.filter((existing_device) => existing_device !== action.device)
        )
      }
    )

    services.splice(index, 1, new_service_state)

    return Object.freeze({
      ...state,
      services: Object.freeze(services),
      active: (
        action.service === data.phone_service
        ? (
          new_service_state.devices.length === 0
          ? undefined
          : new_service_state.devices[new_service_state.devices.length - 1]
        )
        : state.active
      )
    })
  }

  case SET_ARRIVED_FROM_STATUS_BAR: {
    return Object.freeze({
      ...state,
      back: action.value,
    })
  }

  default: 
    return state
  }
})
