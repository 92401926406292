import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import cx from "classnames"

import { IconType } from "@pag/center/components/icon/types"
import icons from "@pag/center/components/icon/icons"
import { ROUTES } from "@pag/center/views/routes"
import Icon from "@pag/center/components/icon/Icon"
import I18nLabel from "@pag/center/components/I18nLabel/I18nLabel"

import "./tile.scss"

interface ITile {
  icon: IconType | string,
  text?: string
  isSmall?: boolean
  url?: string
  isPassengerScreen?: boolean
  className?: string
  disabled?: boolean
  pressed?: boolean
  settingsList?: any
  count?: number
  callback?: () => void
  disabledClick?: () => void
  onLongPress?: () => void
  setVisibleList?: (list: any) => void
  updateLocationHistory?: (location: Location, source: string) => void
}

const Tile: React.FunctionComponent<ITile> = ({
  text,
  icon,
  isSmall,
  url,
  isPassengerScreen,
  className,
  disabled,
  pressed,
  setVisibleList,
  updateLocationHistory,
  settingsList,
  count,
  callback,
  disabledClick,
  onLongPress
}) => {
  const [ long, setLong ] = useState(false)
  const [ skipClick, setSkipClick ] = useState(false)
  const history = useHistory()
  const size = isSmall ? 62 : 90

  useEffect(
    () => {
      if (long) {
        const timeout = setTimeout(
          () => {
            if (onLongPress) {
              onLongPress()
            }
            setLong(false)
            setSkipClick(true)
          },
          850
        )

        return () => clearTimeout(timeout)
      }
    },
    [ long, onLongPress ]
  )

  return (
    <label
      className={cx(
        "tile",
        "state-focusable",
        {
          "state-pressed": pressed,
          "tile--small": isSmall,
          "state-disabled": disabled,
        },
        className
      )}
      onPointerDown={() => {
        if (onLongPress !== undefined) {
          setLong(true)
        }
      }}
      onPointerUp={() => setLong(false)}
      onPointerLeave={() => setLong(false)}
      onClick={(event: any) => {
        if (skipClick) {
          event.preventDefault()
          setSkipClick(false)
          return
        }
        if (!disabled) {
          if (url !== undefined) {
            history.push(url)
            if (!isPassengerScreen) {
              // @ts-ignore
              updateLocationHistory(url, "center")
            }
          } else if (settingsList) {
            history.push(ROUTES.SETTINGS)
            setVisibleList!(settingsList)
          }
          if (typeof callback === "function") {
            callback()
          }
        } else if (typeof disabledClick === "function") {
          disabledClick()
        }
      }}
    >
      <div className="tile__icon-wrapper">
        {(
          Object.values(icons).includes(icon as IconType)
          ? <Icon iconClass="tile_icon" iconType={icon as IconType} />
          : <img className="tile__icon" draggable="false" onDragStart={(event) => event.preventDefault()} src={icon} alt={text} height={String(size)} width={String(size)} />
        )}
        {count && <div className="tile__count">{count}</div>}
      </div>

      {(text !== undefined && isSmall !== true) && (
        <div className="tile__text">
          <I18nLabel text={text} />
        </div>
      )}
    </label>
  )
}

export default Tile
