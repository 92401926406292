import {
  SET_CLIMATE_ON,
  SET_CLIMATE_OFF,
  SET_ECO_MODE,
  SET_AC,
  SET_CLIMATE_EBF,
  State,
  Action
} from "./types"

const initialState: State = {
  active: true,
  eco: true,
  ac: true,
  ebf: true,
  outside_temp: 25
}

export default Object.freeze(function climateReducer(
  state: State = initialState,
  action: Action
): State {
  switch (action.type) {

  case SET_CLIMATE_ON: {
    return {
      ...state,
      active: true,
      eco: true,
      ac: true
    }
  }

  case SET_CLIMATE_OFF: {
    return {
      ...state,
      active: false,
      eco: false,
      ac: false,
    }
  }
  
  case SET_ECO_MODE: {
    return {
      ...state,
      active: true,
      eco: action.value
    }
  }
  
  case SET_AC: {
    return {
      ...state,
      active: true,
      ac: action.value
    }
  }

  case SET_CLIMATE_EBF: {
    return {
      ...state,
      ebf: action.value,
    }
  }

  default: 
    return state
  }
})
