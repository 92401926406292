const populate = (...names: string[]) => (...elements: any[]): Readonly<readonly any[]> => (
  Object.freeze(
    elements.map(
      (element: any[]) => Object.freeze(
        element.reduce(
          (result: { [index: string]: any}, item: any, index: number) => {
            if (item !== undefined) {
              result[names[index]] = item
            }
            return result
          },
          Object.create(null)
        )
      )
    )
  )
)

const populate_loose = (...names: string[]) => (...elements: any[]): Readonly<any[]> => (
  Object.freeze(
    elements.map(
      (element: any[]) => element.reduce(
        (result: { [index: string]: any}, item: any, index: number) => {
          if (item !== undefined) {
            result[names[index]] = item
          }
          return result
        },
        Object.create(null)
      )
    )
  )
)

export default Object.freeze({
  populate,
  populate_loose
})
