import { IconType } from "@pag/center/components/icon/types"

export const INIT_CONNECT_DEVICE = "INIT_CONNECT_DEVICE"
export const CANCEL_CONNECT_DEVICE = "CANCEL_CONNECT_DEVICE"
export const CONNECT_DEVICE = "CONNECT_DEVICE"
export const REMOVE_DEVICE = "REMOVE_DEVICE"
export const SET_PREFERRED_DEVICE = "SET_PREFERRED_DEVICE"
export const SET_ACTIVE_DEVICE = "SET_ACTIVE_DEVICE"
export const SET_ARRIVED_FROM_STATUS_BAR = "SET_ARRIVED_FROM_STATUS_BAR"
export const TURN_DEVICE_SERVICE_ON = "TURN_DEVICE_SERVICE_ON"
export const TURN_DEVICE_SERVICE_OFF = "TURN_DEVICE_SERVICE_OFF"

export type Service = {
  name: string
  icon: IconType
}

export type Device = {
  name: string
  icon: IconType
  services: Service[] // @NOTE(kirill): This defines what services available for the device.
}

export type ServiceState = {
  service: Service,
  devices: Device[]
}

export type State = {

  // All the connected devices.
  devices: readonly Device[]

  // Device that is marked with a star.
  preferred: Device | undefined

  // Device that is currently active, based on all the previous states.
  active: Device | undefined

  // All the services.
  services: readonly ServiceState[]

  // Service that is about to comment.
  connecting: Service | undefined

  // Opened from status bar icon.
  back: boolean

}

export type InitConnectDeviceAction = {
  type: typeof INIT_CONNECT_DEVICE
  service: Service
}

export type RemoveDeviceAction = {
  type: typeof REMOVE_DEVICE
  device: Device
}

export type CancelConnectDeviceAction = {
  type: typeof CANCEL_CONNECT_DEVICE
}

export type ConnectDeviceAction = {
  type: typeof CONNECT_DEVICE
  device: Device
}

export type SetActiveDeviceAction = {
  type: typeof SET_ACTIVE_DEVICE
  device: Device
}

export type SetPreferredDeviceAction = {
  type: typeof SET_PREFERRED_DEVICE
  device: Device | undefined
}

export type TurnServiceOnAction = {
  type: typeof TURN_DEVICE_SERVICE_ON
  service: Service
  device: Device
}

export type TurnServiceOffAction = {
  type: typeof TURN_DEVICE_SERVICE_OFF
  service: Service
  device: Device
}

export type SetArrivedFromStatusBarAction = {
  type: typeof SET_ARRIVED_FROM_STATUS_BAR
  value: boolean
}

export type Action =
  | TurnServiceOnAction
  | TurnServiceOffAction
  | InitConnectDeviceAction 
  | CancelConnectDeviceAction 
  | ConnectDeviceAction 
  | SetPreferredDeviceAction 
  | SetActiveDeviceAction 
  | SetArrivedFromStatusBarAction
  | RemoveDeviceAction

