export const SET_CLIMATE_ON = "SET_CLIMATE_ON"
export const SET_CLIMATE_OFF = "SET_CLIMATE_OFF"
export const SET_CLIMATE_EBF = "SET_CLIMATE_EBF"
export const SET_ECO_MODE = "SET_ECO_MODE"
export const SET_AC = "SET_AC"

export type State = {
  active: boolean,
  eco: boolean,
  ac: boolean,
  ebf: boolean,
  outside_temp: number
}

export type Handle = {
  className: string
  vent: string
}

export type SetActiveAction = {
  type: typeof SET_CLIMATE_OFF | typeof SET_CLIMATE_ON
}

export type SetEBFActiveAction = {
  type: typeof SET_CLIMATE_EBF
  value: boolean
}

export type SetEcoAction = {
  type: typeof SET_ECO_MODE
  value: boolean
}

export type SetAcAction = {
  type: typeof SET_AC
  value: boolean
}

export type Action = SetActiveAction | SetEBFActiveAction | SetEcoAction | SetAcAction
