export default {
  my_screen_config: "icon-my_screen_config",
  apple_carplay: "icon-apple_carplay",
  android_auto: "icon-android_auto",
  arrow_open_left: "icon-arrow_open_left",
  arrow_open_right: "icon-arrow_open_right",
  google_search: "icon-google_search",
  google_search_inactive: "icon-google_search_inactive",
  magnify_glass: "icon-magnify_glass",
  microphone: "icon-microphone",
  microphone_crossed: "icon-microphone_crossed",
  note: "icon-note",
  calendar: "icon-calendar",
  compass: "icon-compass",
  option_open: "icon-option_open",
  person: "icon-person",
  processing: "icon-processing",
  voice_mail: "icon-voice_mail",
  car_limousine: "icon-car_limousine",
  car_limousine_niveau: "icon-car_limousine_niveau",
  car_limousine_spoiler: "icon-car_limousine_spoiler",
  e_sound: "icon-e_sound",
  pasm: "icon-pasm",
  "3D_map": "icon-3D_map",
  scale_zoom_auto: "icon-scale_zoom_auto",
  guidance_arrow: "icon-guidance_arrow",
  scale_zoom_manually: "icon-scale_zoom_manually",
  mute: "icon-mute",
  my_location: "icon-my_location",
  north_arrow: "icon-north_arrow",
  readout: "icon-readout",
  satellite: "icon-satellite",
  destination_flag_arrow: "icon-destination_flag_arrow",
  stopover_flag_arrow: "icon-stopover_flag_arrow",
  selena: "icon-selena",
  speed_limit_60: "icon-speed_limit_60",
  speed_limit_rain: "icon-speed_limit_rain",
  view: "icon-view",
  weather: "icon-weather",
  note_add_favorite: "icon-note_add_favorite",
  note_circle: "icon-note_circle",
  pause: "icon-pause",
  play: "icon-play",
  play_circled: "icon-play_circled",
  play_circled_shuffle: "icon-play_circled_shuffle",
  playlist: "icon-playlist",
  repeat: "icon-repeat",
  replay: "icon-replay",
  shuffle: "icon-shuffle",
  skip_backward: "icon-skip_backward",
  skip_forward: "icon-skip_forward",
  logo_spotify: "icon-logo_spotify",
  logo_spotify_color: "icon-logo_spotify_color",
  spotify_home: "icon-spotify_home",
  spotify_recent: "icon-spotify_recent",
  spotify_browse: "icon-spotify_browse",
  spotify_library: "icon-spotify_library",
  spotify_heart: "icon-spotify_heart",
  spotify_heart_outline: "icon-spotify_heart_outline",
  star: "icon-star",
  star_outline: "icon-star_outline",
  stop_scan: "icon-stop_scan",
  tag: "icon-tag",
  bluetooth: "icon-bluetooth",
  channel: "icon-channel",
  cover: "icon-cover",
  live: "icon-live",
  radio: "icon-radio",
  fm_linking_list: "icon-fm_linking_list",
  usb: "icon-usb",
  art_culture: "icon-art_culture",
  charging_station: "icon-charging_station",
  charging_station_hpc: "icon-charging_station_hpc",
  destination_flag: "icon-destination_flag",
  dls_top: "icon-dls_top",
  home: "icon-home",
  laptop: "icon-laptop",
  microphone_hand: "icon-microphone_hand",
  nightlife: "icon-nightlife",
  parking: "icon-parking",
  sports: "icon-sports",
  tour: "icon-tour",
  delete_numbers: "icon-delete_numbers",
  delete_numbers_arabic: "icon-delete_numbers_arabic",
  phone_hang_up: "icon-phone_hang_up",
  battery_0: "icon-battery_0",
  battery_1: "icon-battery_1",
  battery_2: "icon-battery_2",
  battery_3: "icon-battery_3",
  battery_4: "icon-battery_4",
  battery_blank: "icon-battery_blank",
  cancel: "icon-cancel",
  cell_phone: "icon-cell_phone",
  cell_phone_multi: "icon-cell_phone_multi",
  group: "icon-group",
  information: "icon-information",
  phone_add_call: "icon-phone_add_call",
  phone_incoming_call: "icon-phone_incoming_call",
  phone_missed_call: "icon-phone_missed_call",
  phone_outgoing_call: "icon-phone_outgoing_call",
  phone_pause_call: "icon-phone_pause_call",
  phone_reactivate_call: "icon-phone_reactivate_call",
  "3g": "icon-3g",
  car_front_j1: "icon-car_front_j1",
  car_limousine_sound_off: "icon-car_limousine_sound_off",
  cell_phone_crossed: "icon-cell_phone_crossed",
  circle_crossed: "icon-circle_crossed",
  connect_app: "icon-connect_app",
  connectivity: "icon-connectivity",
  connectivity_disabled: "icon-connectivity_disabled",
  edge: "icon-edge",
  etc_active: "icon-etc_active",
  etc_error: "icon-etc_error",
  geo_localization: "icon-geo_localization",
  ionisation: "icon-ionisation",
  lte: "icon-lte",
  network_0: "icon-network_0",
  network_0_arrow_forward: "icon-network_0_arrow_forward",
  network_1: "icon-network_1",
  network_1_arrow_forward: "icon-network_1_arrow_forward",
  network_2: "icon-network_2",
  network_2_arrow_forward: "icon-network_2_arrow_forward",
  network_3: "icon-network_3",
  network_3_arrow_forward: "icon-network_3_arrow_forward",
  network_4: "icon-network_4",
  network_4_arrow_forward: "icon-network_4_arrow_forward",
  network_5: "icon-network_5",
  network_5_arrow_forward: "icon-network_5_arrow_forward",
  notification: "icon-notification",
  parking_assistant: "icon-parking_assistant",
  parking_camera: "icon-parking_camera",
  privacy: "icon-privacy",
  privacy_nad: "icon-privacy_nad",
  radio_no_signal: "icon-radio_no_signal",
  recording: "icon-recording",
  sim_card_privacy: "icon-sim_card_privacy",
  wlan: "icon-wlan",
  at: "icon-at",
  dot: "icon-dot",
  enter: "icon-enter",
  enter_arabic: "icon-enter_arabic",
  globe: "icon-globe",
  handwriting: "icon-handwriting",
  keyboard: "icon-keyboard",
  keyboard_hide: "icon-keyboard_hide",
  shift: "icon-shift",
  space: "icon-space",
  speller_characters: "icon-speller_characters",
  speller_delete_numbers: "icon-speller_delete_numbers",
  speller_delete_numbers_arabic: "icon-speller_delete_numbers_arabic",
  speller_letters: "icon-speller_letters",
  speller_numbers: "icon-speller_numbers",
  radio_button: "icon-radio_button",
  radio_button_checked: "icon-radio_button_checked",
  box: "icon-box",
  box_checked: "icon-box_checked",
  information_outline: "icon-information_outline",
  delete_entry: "icon-delete_entry",
  stopover_flag_add: "icon-stopover_flag_add",
  air_distribution_left: "icon-air_distribution_left",
  circle_filled: "icon-circle_filled",
  eco: "icon-eco",
  ac_eco: "icon-ac_eco",
  ac: "icon-ac",
  air_off: "icon-air_off",
  circle_outline: "icon-circle_outline",
  setting: "icon-setting",
  map_layer: "icon-map_layer",
  air_distance_filled: "icon-air_distance_filled",
  concierge: "icon-concierge",
  air_distance_to_poi: "icon-air_distance_to_poi",
  last_destination: "icon-last_destination",
  address_home: "icon-address_home",
  address_work: "icon-address_work",
  bin: "icon-bin",
  edit: "icon-edit",
  channel_no_signal: "icon-channel_no_signal",
  ipod: "icon-ipod",
  fm_dab_no_signal: "icon-fm_dab_no_signal",
  fm_dab: "icon-fm_dab",
  fm_radio_no_signal: "icon-fm_radio_no_signal",
  fm_radio: "icon-fm_radio",
  fm_radio_dab: "icon-fm_radio_dab",
  sirius_xm_no_signal: "icon-sirius_xm_no_signal",
  sirius_xm: "icon-sirius_xm",
  epg: "icon-epg",
  folder: "icon-folder",
  fm_linking: "icon-fm_linking",
  online_linking: "icon-online_linking",
  fast_backward: "icon-fast_backward",
  fast_forward: "icon-fast_forward",
  car_limousine_auto_recirculation: "icon-car_limousine_auto_recirculation",
  repeat_title: "icon-repeat_title",
  podcast: "icon-podcast",
  warning_filled: "icon-warning_filled",
  aca_lks: "icon-aca_lks",
  back_out_assistant: "icon-back_out_assistant",
  cross_over_assistant: "icon-cross_over_assistant",
  lane_change_assist: "icon-lane_change_assist",
  exit_warning: "icon-exit_warning",
  traffic_assist_j1: "icon-traffic_assist_j1",
  emergency_assist_j1: "icon-emergency_assist_j1",
  arrow_open_up: "icon-arrow_open_up",
  arrow_open_down: "icon-arrow_open_down",
  route: "icon-route",
  seat_right: "icon-seat_right",
  seat_left: "icon-seat_left",
  seat_ventilation_right: "icon-seat_ventilation_right",
  seat_ventilation_left: "icon-seat_ventilation_left",
  phone_receiver_green: "icon-phone_receiver_green",
  message_outline: "icon-message_outline",
  lane_departure_warning_system_j1: "icon-lane_departure_warning_system_j1",
  emergency_assist: "icon-emergency_assist",
  air_center_j1: "icon-air_center_j1",
  seat_heating_right: "icon-seat_heating_right",
  seat_heating_left: "icon-seat_heating_left",
  road: "icon-road",
  freeway: "icon-freeway",
  hov_avoid_not: "icon-hov_avoid_not",
  freeway_toll: "icon-freeway_toll",
  avoid_ferry_car_train_not: "icon-avoid_ferry_car_train_not",
  tunnel: "icon-tunnel",
  altitude: "icon-altitude",
  message_draft: "icon-message_draft",
  message_inbox: "icon-message_inbox",
  message_outbox: "icon-message_outbox",
  message_sent: "icon-message_sent",
  message_template: "icon-message_template",
  ass_basic: "icon-ass_basic",
  options_more: "icon-options_more",
  ass_j1: "icon-ass_j1",
  pdc_mute: "icon-pdc_mute",
  on_off: "icon-on_off",
  "4G": "icon-4G",
  predictive_maintenance: "icon-predictive_maintenance",
  speller_coordinates: "icon-speller_coordinates",
  warning_sign: "icon-warning_sign",
  poi: "icon-poi",
  stopover_flag: "icon-stopover_flag",
  night_vision: "icon-night_vision",
  update_center: "icon-update_center",
  air_style_right: "icon-air_style_right",
  phone_receiver: "icon-phone_receiver",
  electricity: "icon-electricity",
  contacts: "icon-contacts",
  wind: "icon-wind",
  sun: "icon-sun",
  rain: "icon-rain",
  trailer_view: "icon-trailer_view",
  ipa: "icon-ipa",
  rtv_panorama_view: "icon-rtv_panorama_view",
  maneuver_assist_j1: "icon-maneuver_assist_j1",
  cleaning_camera: "icon-cleaning_camera",
  camera_arrow: "icon-camera_arrow",
  message_write: "icon-message_write",
  destination_flag_stop: "icon-destination_flag_stop",
  stopover_flag_delete: "icon-stopover_flag_delete",
  ambient_light: "icon-ambient_light",
  four_squares: "icon-four_squares",
  cell_phone_link: "icon-cell_phone_link",
  porsche_connect_app: "icon-porsche_connect_app",
  maneuver_assist_j1_off: "icon-maneuver_assist_j1_off",
  ipa_scanner: "icon-ipa_scanner",
  trip_continuous: "icon-trip_continuous",
  trip_personal: "icon-trip_personal",
  trip_since_refuelling: "icon-trip_since_refuelling",
  trip_since: "icon-trip_since",
  PoweredByGracenote_Logo: "icon-PoweredByGracenote_Logo",
  radionet_Logo: "icon-radionet_Logo",
  circle: "icon-circle",
  camera: "icon-camera",
  indicator_warning: "icon-indicator_warning",
  arrow_up: "icon-arrow_up",
  update: "icon-update",
  comma_fullstop_hiragana: "icon-comma_fullstop_hiragana",
  hiragana_function_key: "icon-hiragana_function_key",
  poi_filled: "icon-poi_filled",
  apple_icloud: "icon-apple_icloud",
  split_calls: "icon-split_calls",
  more: "icon-more",
  ac_fan_only: "icon-ac_fan_only",
  addressbook: "icon-addressbook",
  all: "icon-all",
  all_outline: "icon-all_outline",
  asterisk: "icon-asterisk",
  asterisk_asia: "icon-asterisk_asia",
  bubble_info: "icon-bubble_info",
  communication: "icon-communication",
  comma_fullstop_taiwan: "icon-comma_fullstop_taiwan",
  comma_point_hiragana: "icon-comma_point_hiragana",
  logo_sirius_xm_long: "icon-logo_sirius_xm_long",
  logo_sirius_xm_short: "icon-logo_sirius_xm_short",
  maximize: "icon-maximize",
  minimize: "icon-minimize",
  parking_cross_backward_left: "icon-parking_cross_backward_left",
  parking_cross_backward_right: "icon-parking_cross_backward_right",
  parking_cross_forward_left: "icon-parking_cross_forward_left",
  parking_cross_forward_right: "icon-parking_cross_forward_right",
  parking_garage: "icon-parking_garage",
  parking_parallel_left: "icon-parking_parallel_left",
  parking_parallel_right: "icon-parking_parallel_right",
  ipa_search_right: "icon-ipa_search_right",
  ipa_search_left: "icon-ipa_search_left",
  genre: "icon-genre",
  family: "icon-family",
  knowledge: "icon-knowledge",
  festival: "icon-festival",
  list: "icon-list",
  bookmark: "icon-bookmark",
  themes: "icon-themes",
  date_weekend: "icon-date_weekend",
  date_today: "icon-date_today",
  date_day: "icon-date_day",
  check: "icon-check",
  plus: "icon-plus",
  minus: "icon-minus",
  air_distribution_diffuse: "icon-air_distribution_diffuse",
  air_distribution_focus: "icon-air_distribution_focus",
  pid_upcoming_events_right_curve: "icon-pid_upcoming_events_right_curve",
  pid_upcoming_events_branch_off_left: "icon-pid_upcoming_events_branch_off_left",
  pid_upcoming_events_branch_off_right: "icon-pid_upcoming_events_branch_off_right",
  pid_upcoming_events_highway_exit_left: "icon-pid_upcoming_events_highway_exit_left",
  pid_upcoming_events_highway_exit_right: "icon-pid_upcoming_events_highway_exit_right",
  pid_upcoming_events_junction: "icon-pid_upcoming_events_junction",
  pid_upcoming_events_left_curve: "icon-pid_upcoming_events_left_curve",
  pid_upcoming_events_priority_to_left_a: "icon-pid_upcoming_events_priority_to_left_a",
  pid_upcoming_events_priority_to_left_b: "icon-pid_upcoming_events_priority_to_left_b",
  pid_upcoming_events_priority_to_right_a: "icon-pid_upcoming_events_priority_to_right_a",
  pid_upcoming_events_priority_to_right_b: "icon-pid_upcoming_events_priority_to_right_b",
  other_road: "icon-other_road",
  bypass: "icon-bypass",
  ass_disabled_j1: "icon-ass_disabled_j1",
  recuperation: "icon-recuperation",
  clock_time: "icon-clock_time",
  update_save: "icon-update_save",
  arrow_down: "icon-arrow_down",
  climate: "icon-climate",
  ipa_leave_left: "icon-ipa_leave_left",
  ipa_leave_right: "icon-ipa_leave_right",
  logo_radio_net_long: "icon-logo_radio_net_long",
  drag_vertical: "icon-drag_vertical",
  road_side_assistance: "icon-road_side_assistance",
  air_distribution_left_down: "icon-air_distribution_left_down",
  air_distribution_left_mid: "icon-air_distribution_left_mid",
  air_distribution_left_up: "icon-air_distribution_left_up",
  air_distribution_right_down: "icon-air_distribution_right_down",
  air_distribution_right_mid: "icon-air_distribution_right_mid",
  air_distribution_right_up: "icon-air_distribution_right_up",
  air_style_left_3: "icon-air_style_left_3",
  air_style_left_2: "icon-air_style_left_2",
  air_style_left_1: "icon-air_style_left_1",
  air_style_right_3: "icon-air_style_right_3",
  air_style_right_2: "icon-air_style_right_2",
  air_style_right_1: "icon-air_style_right_1",
  destination_flag_left: "icon-destination_flag_left",
  destination_flag_offroad: "icon-destination_flag_offroad",
  destination_flag_right: "icon-destination_flag_right",
  stopover_flag_left: "icon-stopover_flag_left",
  stopover_flag_offroad: "icon-stopover_flag_offroad",
  stopover_flag_right: "icon-stopover_flag_right",
  ac_max: "icon-ac_max",
  conference_call: "icon-conference_call",
  scale_zoom_stopover: "icon-scale_zoom_stopover",
  scale_zoom_destination: "icon-scale_zoom_destination",
  air_distribution_right: "icon-air_distribution_right",
  saved: "icon-saved",
  pilopa: "icon-pilopa",
  logo_bose: "icon-logo_bose",
  logo_burmester: "icon-logo_burmester",
  notification_extended: "icon-notification_extended",
  lg_dr_e0: "icon-lg_dr_e0",
  lg_dr_c0: "icon-lg_dr_c0",
  lg_dr_a0: "icon-lg_dr_a0",
  lg_dr_92: "icon-lg_dr_92",
  lg_dr_72: "icon-lg_dr_72",
  lg_dr_60: "icon-lg_dr_60",
  lg_dr_40: "icon-lg_dr_40",
  lg_dr_20: "icon-lg_dr_20",
  lg_dr_00: "icon-lg_dr_00",
  lg_2s_dr_e0_mitte: "icon-lg_2s_dr_e0_mitte",
  lg_2s_dr_e0_links_lang: "icon-lg_2s_dr_e0_links_lang",
  lg_2s_dr_e0_links_kurz: "icon-lg_2s_dr_e0_links_kurz",
  lg_2s_dr_c0_mitte: "icon-lg_2s_dr_c0_mitte",
  lg_2s_dr_c0_mitte_kurz: "icon-lg_2s_dr_c0_mitte_kurz",
  lg_2s_dr_c0_links_lang: "icon-lg_2s_dr_c0_links_lang",
  lg_2s_dr_c0_links_kurz: "icon-lg_2s_dr_c0_links_kurz",
  lg_2s_dr_a0_mitte: "icon-lg_2s_dr_a0_mitte",
  lg_2s_dr_a0_mitte_kurz: "icon-lg_2s_dr_a0_mitte_kurz",
  lg_2s_dr_a0_links_lang: "icon-lg_2s_dr_a0_links_lang",
  lg_2s_dr_a0_links_kurz: "icon-lg_2s_dr_a0_links_kurz",
  lg_2s_dr_92_mitte: "icon-lg_2s_dr_92_mitte",
  lg_2s_dr_92_links: "icon-lg_2s_dr_92_links",
  lg_2s_dr_72_rechts: "icon-lg_2s_dr_72_rechts",
  lg_2s_dr_72_mitte: "icon-lg_2s_dr_72_mitte",
  lg_2s_dr_60_rechts_lang: "icon-lg_2s_dr_60_rechts_lang",
  lg_2s_dr_60_rechts_kurz: "icon-lg_2s_dr_60_rechts_kurz",
  lg_2s_dr_60_mitte: "icon-lg_2s_dr_60_mitte",
  lg_2s_dr_60_mitte_kurz: "icon-lg_2s_dr_60_mitte_kurz",
  lg_2s_dr_40_rechts_lang: "icon-lg_2s_dr_40_rechts_lang",
  lg_2s_dr_40_rechts_kurz: "icon-lg_2s_dr_40_rechts_kurz",
  lg_2s_dr_40_mitte: "icon-lg_2s_dr_40_mitte",
  lg_2s_dr_40_mitte_kurz: "icon-lg_2s_dr_40_mitte_kurz",
  lg_2s_dr_20_rechts_lang: "icon-lg_2s_dr_20_rechts_lang",
  lg_2s_dr_20_rechts_kurz: "icon-lg_2s_dr_20_rechts_kurz",
  lg_2s_dr_20_mitte: "icon-lg_2s_dr_20_mitte",
  lg_2s_dr_00_rechts: "icon-lg_2s_dr_00_rechts",
  lg_2s_dr_00_links: "icon-lg_2s_dr_00_links",
  lg_3s_dr_e0_mitte: "icon-lg_3s_dr_e0_mitte",
  lg_3s_dr_e0_mitte_lang: "icon-lg_3s_dr_e0_mitte_lang",
  lg_3s_dr_e0_links: "icon-lg_3s_dr_e0_links",
  lg_3s_dr_e0_links_lang: "icon-lg_3s_dr_e0_links_lang",
  lg_3s_dr_c0_mitte_lang: "icon-lg_3s_dr_c0_mitte_lang",
  lg_3s_dr_c0_mitte_kurz: "icon-lg_3s_dr_c0_mitte_kurz",
  lg_3s_dr_c0_links: "icon-lg_3s_dr_c0_links",
  lg_3s_dr_c0_links_lang: "icon-lg_3s_dr_c0_links_lang",
  lg_3s_dr_c0_links_kurz: "icon-lg_3s_dr_c0_links_kurz",
  lg_3s_dr_a0_mitte: "icon-lg_3s_dr_a0_mitte",
  lg_3s_dr_a0_mitte_lang: "icon-lg_3s_dr_a0_mitte_lang",
  lg_3s_dr_a0_mitte_kurz: "icon-lg_3s_dr_a0_mitte_kurz",
  lg_3s_dr_a0_links: "icon-lg_3s_dr_a0_links",
  lg_3s_dr_a0_links_lang: "icon-lg_3s_dr_a0_links_lang",
  lg_3s_dr_a0_links_kurz: "icon-lg_3s_dr_a0_links_kurz",
  lg_3s_dr_92_mitte: "icon-lg_3s_dr_92_mitte",
  lg_3s_dr_92_mitte_kurz: "icon-lg_3s_dr_92_mitte_kurz",
  lg_3s_dr_92_links: "icon-lg_3s_dr_92_links",
  lg_3s_dr_92_links_kurz: "icon-lg_3s_dr_92_links_kurz",
  lg_3s_dr_72_rechts: "icon-lg_3s_dr_72_rechts",
  lg_3s_dr_72_rechts_kurz: "icon-lg_3s_dr_72_rechts_kurz",
  lg_3s_dr_72_mitte: "icon-lg_3s_dr_72_mitte",
  lg_3s_dr_72_mitte_kurz: "icon-lg_3s_dr_72_mitte_kurz",
  lg_3s_dr_60_rechts: "icon-lg_3s_dr_60_rechts",
  lg_3s_dr_60_rechts_lang: "icon-lg_3s_dr_60_rechts_lang",
  lg_3s_dr_60_rechts_kurz: "icon-lg_3s_dr_60_rechts_kurz",
  lg_3s_dr_60_mitte: "icon-lg_3s_dr_60_mitte",
  lg_3s_dr_60_mitte_lang: "icon-lg_3s_dr_60_mitte_lang",
  lg_3s_dr_60_mitte_kurz: "icon-lg_3s_dr_60_mitte_kurz",
  lg_3s_dr_40_rechts: "icon-lg_3s_dr_40_rechts",
  lg_3s_dr_40_rechts_lang: "icon-lg_3s_dr_40_rechts_lang",
  lg_3s_dr_40_rechts_kurz: "icon-lg_3s_dr_40_rechts_kurz",
  lg_3s_dr_40_mitte_lang: "icon-lg_3s_dr_40_mitte_lang",
  lg_3s_dr_40_mitte_kurz: "icon-lg_3s_dr_40_mitte_kurz",
  lg_3s_dr_20_rechts: "icon-lg_3s_dr_20_rechts",
  lg_3s_dr_20_rechts_lang: "icon-lg_3s_dr_20_rechts_lang",
  lg_3s_dr_20_mitte: "icon-lg_3s_dr_20_mitte",
  lg_3s_dr_20_mitte_lang: "icon-lg_3s_dr_20_mitte_lang",
  lg_3s_dr_00_rechts: "icon-lg_3s_dr_00_rechts",
  lg_3s_dr_00_mitte: "icon-lg_3s_dr_00_mitte",
  lg_3s_dr_00_links: "icon-lg_3s_dr_00_links",
  lg_special_lane_widget_points: "icon-lg_special_lane_widget_points",
  lg_special_buslane: "icon-lg_special_buslane",
  "04_base_layer_RoundAbout": "icon-04_base_layer_RoundAbout",
  "04_00_p_TurnArrow_UTurnC0": "icon-04_00_p_TurnArrow_UTurnC0",
  "04_00_p_TurnArrow_UTurn40": "icon-04_00_p_TurnArrow_UTurn40",
  "04_00_p_TurnArrow_StandardTurnE0": "icon-04_00_p_TurnArrow_StandardTurnE0",
  "04_00_p_TurnArrow_StandardTurnC0": "icon-04_00_p_TurnArrow_StandardTurnC0",
  "04_00_p_TurnArrow_StandardTurnA0": "icon-04_00_p_TurnArrow_StandardTurnA0",
  "04_00_p_TurnArrow_StandardTurn60": "icon-04_00_p_TurnArrow_StandardTurn60",
  "04_00_p_TurnArrow_StandardTurn40": "icon-04_00_p_TurnArrow_StandardTurn40",
  "04_00_p_TurnArrow_StandardTurn20": "icon-04_00_p_TurnArrow_StandardTurn20",
  "04_00_p_TurnArrow_StandardTurn00": "icon-04_00_p_TurnArrow_StandardTurn00",
  "04_00_p_TurnArrow_SquareTrsRightF0": "icon-04_00_p_TurnArrow_SquareTrsRightF0",
  "04_00_p_TurnArrow_SquareTrsRightE0": "icon-04_00_p_TurnArrow_SquareTrsRightE0",
  "04_00_p_TurnArrow_SquareTrsRightB0_D0": "icon-04_00_p_TurnArrow_SquareTrsRightB0_D0",
  "04_00_p_TurnArrow_SquareTrsRight90_A0": "icon-04_00_p_TurnArrow_SquareTrsRight90_A0",
  "04_00_p_TurnArrow_SquareTrsRight80": "icon-04_00_p_TurnArrow_SquareTrsRight80",
  "04_00_p_TurnArrow_SquareTrsRight60_70": "icon-04_00_p_TurnArrow_SquareTrsRight60_70",
  "04_00_p_TurnArrow_SquareTrsRight30_50": "icon-04_00_p_TurnArrow_SquareTrsRight30_50",
  "04_00_p_TurnArrow_SquareTrsRight20": "icon-04_00_p_TurnArrow_SquareTrsRight20",
  "04_00_p_TurnArrow_SquareTrsRight10": "icon-04_00_p_TurnArrow_SquareTrsRight10",
  "04_00_p_TurnArrow_SquareTrsRight00": "icon-04_00_p_TurnArrow_SquareTrsRight00",
  "04_00_p_TurnArrow_SquareTrsLeftF0": "icon-04_00_p_TurnArrow_SquareTrsLeftF0",
  "04_00_p_TurnArrow_SquareTrsLeftE0": "icon-04_00_p_TurnArrow_SquareTrsLeftE0",
  "04_00_p_TurnArrow_SquareTrsLeftB0_D0": "icon-04_00_p_TurnArrow_SquareTrsLeftB0_D0",
  "04_00_p_TurnArrow_SquareTrsLeft90_A0": "icon-04_00_p_TurnArrow_SquareTrsLeft90_A0",
  "04_00_p_TurnArrow_SquareTrsLeft80": "icon-04_00_p_TurnArrow_SquareTrsLeft80",
  "04_00_p_TurnArrow_SquareTrsLeft60_70": "icon-04_00_p_TurnArrow_SquareTrsLeft60_70",
  "04_00_p_TurnArrow_SquareTrsLeft30_50": "icon-04_00_p_TurnArrow_SquareTrsLeft30_50",
  "04_00_p_TurnArrow_SquareTrsLeft20": "icon-04_00_p_TurnArrow_SquareTrsLeft20",
  "04_00_p_TurnArrow_SquareTrsLeft10": "icon-04_00_p_TurnArrow_SquareTrsLeft10",
  "04_00_p_TurnArrow_SquareTrsLeft00": "icon-04_00_p_TurnArrow_SquareTrsLeft00",
  "04_00_p_TurnArrow_ServiceRoadRight00": "icon-04_00_p_TurnArrow_ServiceRoadRight00",
  "04_00_p_TurnArrow_ServiceRoadLeft00": "icon-04_00_p_TurnArrow_ServiceRoadLeft00",
  "04_00_p_TurnArrow_RoundAboutTrsRightD0_F0": "icon-04_00_p_TurnArrow_RoundAboutTrsRightD0_F0",
  "04_00_p_TurnArrow_RoundAboutTrsRightC0": "icon-04_00_p_TurnArrow_RoundAboutTrsRightC0",
  "04_00_p_TurnArrow_RoundAboutTrsRight90_B0": "icon-04_00_p_TurnArrow_RoundAboutTrsRight90_B0",
  "04_00_p_TurnArrow_RoundAboutTrsRight80": "icon-04_00_p_TurnArrow_RoundAboutTrsRight80",
  "04_00_p_TurnArrow_RoundAboutTrsRight50_70": "icon-04_00_p_TurnArrow_RoundAboutTrsRight50_70",
  "04_00_p_TurnArrow_RoundAboutTrsRight40": "icon-04_00_p_TurnArrow_RoundAboutTrsRight40",
  "04_00_p_TurnArrow_RoundAboutTrsRight10_30": "icon-04_00_p_TurnArrow_RoundAboutTrsRight10_30",
  "04_00_p_TurnArrow_RoundAboutTrsRight00": "icon-04_00_p_TurnArrow_RoundAboutTrsRight00",
  "04_00_p_TurnArrow_RoundAboutTrsLeftD0_F0": "icon-04_00_p_TurnArrow_RoundAboutTrsLeftD0_F0",
  "04_00_p_TurnArrow_RoundAboutTrsLeftC0": "icon-04_00_p_TurnArrow_RoundAboutTrsLeftC0",
  "04_00_p_TurnArrow_RoundAboutTrsLeft90_B0": "icon-04_00_p_TurnArrow_RoundAboutTrsLeft90_B0",
  "04_00_p_TurnArrow_RoundAboutTrsLeft80": "icon-04_00_p_TurnArrow_RoundAboutTrsLeft80",
  "04_00_p_TurnArrow_RoundAboutTrsLeft50_70": "icon-04_00_p_TurnArrow_RoundAboutTrsLeft50_70",
  "04_00_p_TurnArrow_RoundAboutTrsLeft40": "icon-04_00_p_TurnArrow_RoundAboutTrsLeft40",
  "04_00_p_TurnArrow_RoundAboutTrsLeft10_30": "icon-04_00_p_TurnArrow_RoundAboutTrsLeft10_30",
  "04_00_p_TurnArrow_RoundAboutTrsLeft00": "icon-04_00_p_TurnArrow_RoundAboutTrsLeft00",
  "04_00_p_TurnArrow_OffRoadD0_F0": "icon-04_00_p_TurnArrow_OffRoadD0_F0",
  "04_00_p_TurnArrow_OffRoadC0": "icon-04_00_p_TurnArrow_OffRoadC0",
  "04_00_p_TurnArrow_OffRoad90_B0": "icon-04_00_p_TurnArrow_OffRoad90_B0",
  "04_00_p_TurnArrow_OffRoad80": "icon-04_00_p_TurnArrow_OffRoad80",
  "04_00_p_TurnArrow_OffRoad50_70": "icon-04_00_p_TurnArrow_OffRoad50_70",
  "04_00_p_TurnArrow_OffRoad40": "icon-04_00_p_TurnArrow_OffRoad40",
  "04_00_p_TurnArrow_OffRoad10_30": "icon-04_00_p_TurnArrow_OffRoad10_30",
  "04_00_p_TurnArrow_OffRoad00": "icon-04_00_p_TurnArrow_OffRoad00",
  "04_00_p_TurnArrow_MichiganUTurnC0": "icon-04_00_p_TurnArrow_MichiganUTurnC0",
  "04_00_p_TurnArrow_MichiganUTurn40": "icon-04_00_p_TurnArrow_MichiganUTurn40",
  "04_00_p_TurnArrow_ForkC0": "icon-04_00_p_TurnArrow_ForkC0",
  "04_00_p_TurnArrow_Fork40": "icon-04_00_p_TurnArrow_Fork40",
  "04_00_p_TurnArrow_FollowStreetFF": "icon-04_00_p_TurnArrow_FollowStreetFF",
  "04_00_p_TurnArrow_FollowStreetC0": "icon-04_00_p_TurnArrow_FollowStreetC0",
  "04_00_p_TurnArrow_FollowStreet40": "icon-04_00_p_TurnArrow_FollowStreet40",
  "04_00_p_TurnArrow_ExitRightC0_E0": "icon-04_00_p_TurnArrow_ExitRightC0_E0",
  "04_00_p_TurnArrow_ExitRight00": "icon-04_00_p_TurnArrow_ExitRight00",
  "04_00_p_TurnArrow_ExitLeftt20_40": "icon-04_00_p_TurnArrow_ExitLeftt20_40",
  "04_00_p_TurnArrow_ExitLeft00": "icon-04_00_p_TurnArrow_ExitLeft00",
  "04_00_p_TurnArrow_ChangeLaneC0": "icon-04_00_p_TurnArrow_ChangeLaneC0",
  "04_00_p_TurnArrow_ChangeLane40": "icon-04_00_p_TurnArrow_ChangeLane40",
  arrow_open_full_right: "icon-arrow_open_full_right",
  add_bluetooth: "icon-add_bluetooth",
  source_bluetooth: "icon-source_bluetooth",
  source_usb: "icon-source_usb",
  logo_7eleven: "icon-logo_7eleven",
  logo_afp: "icon-logo_afp",
  logo_ansa: "icon-logo_ansa",
  logo_autonavi: "icon-logo_autonavi",
  logo_db_bahn: "icon-logo_db_bahn",
  logo_deutsche_welle: "icon-logo_deutsche_welle",
  logo_eventseeker: "icon-logo_eventseeker",
  logo_flight_stats: "icon-logo_flight_stats",
  logo_HeFeng: "icon-logo_HeFeng",
  logo_inrix: "icon-logo_inrix",
  logo_merian: "icon-logo_merian",
  logo_meteoGroup: "icon-logo_meteoGroup",
  logo_navitime: "icon-logo_navitime",
  logo_npr: "icon-logo_npr",
  logo_OpisNavx: "icon-logo_OpisNavx",
  logo_pag: "icon-logo_pag",
  logo_parkopedia: "icon-logo_parkopedia",
  logo_rss: "icon-logo_rss",
  logo_SID: "icon-logo_SID",
  logo_Tagesschau: "icon-logo_Tagesschau",
  logo_twitter: "icon-logo_twitter",
  logo_wefind: "icon-logo_wefind",
  logo_Xinhua: "icon-logo_Xinhua",
  logo_yellowmap: "icon-logo_yellowmap",
  arrow_open_full_left: "icon-arrow_open_full_left",
  charge_timer: "icon-charge_timer",
  pid_upcoming_events_traffic_jam_end: "icon-pid_upcoming_events_traffic_jam_end",
  hd_voice: "icon-hd_voice",
  logo_auro_3d: "icon-logo_auro_3d",
  logo_powered_by_google: "icon-logo_powered_by_google",
  b_call_premium: "icon-b_call_premium",
  online_radio: "icon-online_radio",
  video_image_fullscreen: "icon-video_image_fullscreen",
  video_image_fullscreen_close: "icon-video_image_fullscreen_close",
  easy_entry: "icon-easy_entry",
  manual: "icon-manual",
  tour_flag: "icon-tour_flag",
  abs_failure: "icon-abs_failure",
  abs_na: "icon-abs_na",
  steering_wheel: "icon-steering_wheel",
  acc: "icon-acc",
  airbag_warning: "icon-airbag_warning",
  voltage: "icon-voltage",
  brake_worn_linings: "icon-brake_worn_linings",
  brake_wear_na: "icon-brake_wear_na",
  brake_failure: "icon-brake_failure",
  brake_fluid_na: "icon-brake_fluid_na",
  sos: "icon-sos",
  parking_brake: "icon-parking_brake",
  car_limousine_warning: "icon-car_limousine_warning",
  esp_psm_off: "icon-esp_psm_off",
  "4WD": "icon-4WD",
  car_limousine_outside_temp: "icon-car_limousine_outside_temp",
  tempomat: "icon-tempomat",
  seat_belt: "icon-seat_belt",
  hal_1_2: "icon-hal_1_2",
  car_limousine_warning_hybrid: "icon-car_limousine_warning_hybrid",
  pid: "icon-pid",
  childlock: "icon-childlock",
  engine_coolant_level: "icon-engine_coolant_level",
  beam_levelling_control: "icon-beam_levelling_control",
  lgi_traktion: "icon-lgi_traktion",
  lgi_sicht: "icon-lgi_sicht",
  lgi_unfall: "icon-lgi_unfall",
  light_failure: "icon-light_failure",
  traffic_sign_speed_limit_eu: "icon-traffic_sign_speed_limit_eu",
  traffic_sign_speed_limit_nar: "icon-traffic_sign_speed_limit_nar",
  traffic_sign_speed_limit_cn: "icon-traffic_sign_speed_limit_cn",
  speed_limit_warning_vae: "icon-speed_limit_warning_vae",
  traffic_sign_no_entry_eu: "icon-traffic_sign_no_entry_eu",
  smart_key: "icon-smart_key",
  traffic_sign_animal_eu: "icon-traffic_sign_animal_eu",
  traffic_sign_pedestrians_eu: "icon-traffic_sign_pedestrians_eu",
  pasm_warning: "icon-pasm_warning",
  pdcc_j1: "icon-pdcc_j1",
  pos_lever: "icon-pos_lever",
  traffic_sign_traffic_lights_free_right_turn_eu: "icon-traffic_sign_traffic_lights_free_right_turn_eu",
  prioritysign_arrow_na: "icon-prioritysign_arrow_na",
  traffic_sign_traffic_lights_no_turn_nar: "icon-traffic_sign_traffic_lights_no_turn_nar",
  traffic_sign_give_way_eu: "icon-traffic_sign_give_way_eu",
  prioritysign_roundabout: "icon-prioritysign_roundabout",
  traffic_sign_stop_eu: "icon-traffic_sign_stop_eu",
  traffic_sign_traffic_lights_eu: "icon-traffic_sign_traffic_lights_eu",
  cross_lock: "icon-cross_lock",
  warning_tire_pressure: "icon-warning_tire_pressure",
  engine: "icon-engine",
  e_range: "icon-e_range",
  lock_locked: "icon-lock_locked",
  key: "icon-key",
  service: "icon-service",
  voltage_high: "icon-voltage_high",
  voltage_none: "icon-voltage_none",
  coolant_temperature: "icon-coolant_temperature",
  traffic_sign_recognition_j1: "icon-traffic_sign_recognition_j1",
  front_window_wiping_water: "icon-front_window_wiping_water",
  lim: "icon-lim",
  car_limousine_ready_cn: "icon-car_limousine_ready_cn",
  electric_driving_mileage_of_hybrid_vehicles_mode_cn: "icon-electric_driving_mileage_of_hybrid_vehicles_mode_cn",
  limited_performance_mode_power_cn: "icon-limited_performance_mode_power_cn",
  limited_performance_mode_turtle_cn: "icon-limited_performance_mode_turtle_cn",
  ev_charging_connector_lock_cn: "icon-ev_charging_connector_lock_cn",
  car_limousine_system_failure_text_cn: "icon-car_limousine_system_failure_text_cn",
  electric_motor_failure_cn: "icon-electric_motor_failure_cn",
  sds_pickup_list_entry_1: "icon-sds_pickup_list_entry_1",
  sds_pickup_list_entry_2: "icon-sds_pickup_list_entry_2",
  sds_pickup_list_entry_3: "icon-sds_pickup_list_entry_3",
  sds_pickup_list_entry_4: "icon-sds_pickup_list_entry_4",
  propulsion_battery_temperature: "icon-propulsion_battery_temperature",
  parking_light: "icon-parking_light",
  plug: "icon-plug",
  sidelights: "icon-sidelights",
  door_lock_limousine: "icon-door_lock_limousine",
  aca_hands_off: "icon-aca_hands_off",
  fod: "icon-fod",
  update_error: "icon-update_error",
  lgi_panne: "icon-lgi_panne",
  filler_cap_electric_warning_left: "icon-filler_cap_electric_warning_left",
  filler_cap_electric_warning_right: "icon-filler_cap_electric_warning_right",
  gforce_acceleration: "icon-gforce_acceleration",
  gforce_delay: "icon-gforce_delay",
  gforce_lateral_acceleration: "icon-gforce_lateral_acceleration",
  filter: "icon-filter",
  timer: "icon-timer",
  soc_destination_0: "icon-soc_destination_0",
  soc_destination_1: "icon-soc_destination_1",
  soc_destination_2: "icon-soc_destination_2",
  soc_destination_3: "icon-soc_destination_3",
  soc_destination_4: "icon-soc_destination_4",
  soc_destination_5: "icon-soc_destination_5",
  soc_destination_blank: "icon-soc_destination_blank",
  organizer: "icon-organizer",
  logo_taycan: "icon-logo_taycan",
  charging_inductive: "icon-charging_inductive",
  homelink_send: "icon-homelink_send",
  plim_offset: "icon-plim_offset",
  plim_three_digits: "icon-plim_three_digits",
  plim_two_digits: "icon-plim_two_digits",
  poi_listing: "icon-poi_listing",
  brake_key_failure: "icon-brake_key_failure",
  charging_station_warning: "icon-charging_station_warning",
  charging_station_warning_outline: "icon-charging_station_warning_outline",
  logo_cityseeker: "icon-logo_cityseeker",
  eco_plus: "icon-eco_plus",
  update_security: "icon-update_security",
  recuperation_level_adaptive: "icon-recuperation_level_adaptive",
  assist_on_off: "icon-assist_on_off",
  codriver_cockpit: "icon-codriver_cockpit",
  charging_active: "icon-charging_active",
  filler_cap_ac_left: "icon-filler_cap_ac_left",
  filler_cap_ac_right: "icon-filler_cap_ac_right",
  filler_cap_dc_left: "icon-filler_cap_dc_left",
  filler_cap_dc_right: "icon-filler_cap_dc_right",
  filler_cap_dcac_left: "icon-filler_cap_dcac_left",
  filler_cap_dcac_right: "icon-filler_cap_dcac_right",
  plug_left_ac_cn: "icon-plug_left_ac_cn",
  plug_right_dc_cn: "icon-plug_right_dc_cn",
  plug_short_left: "icon-plug_short_left",
  plug_short_right: "icon-plug_short_right",
  charge_premium: "icon-charge_premium",
  parking_plus: "icon-parking_plus",
  air_footwell_left_cold: "icon-air_footwell_left_cold",
  air_footwell_left_warm: "icon-air_footwell_left_warm",
  air_footwell_left: "icon-air_footwell_left",
  air_footwell_right_cold: "icon-air_footwell_right_cold",
  air_footwell_right_warm: "icon-air_footwell_right_warm",
  air_footwell_right: "icon-air_footwell_right",
  speak: "icon-speak",
  message: "icon-message",
  sim_card: "icon-sim_card",
  homelink: "icon-homelink",
  logo_qi: "icon-logo_qi",
  duration: "icon-duration",
  money: "icon-money",
  seat_massage_strength_right: "icon-seat_massage_strength_right",
  seat_massage_strength_left: "icon-seat_massage_strength_left",
  seat_massage_strength_5_right: "icon-seat_massage_strength_5_right",
  seat_massage_strength_5_left: "icon-seat_massage_strength_5_left",
  seat_massage_strength_4_right: "icon-seat_massage_strength_4_right",
  seat_massage_strength_4_left: "icon-seat_massage_strength_4_left",
  seat_massage_strength_3_right: "icon-seat_massage_strength_3_right",
  seat_massage_strength_3_left: "icon-seat_massage_strength_3_left",
  seat_massage_strength_2_right: "icon-seat_massage_strength_2_right",
  seat_massage_strength_2_left: "icon-seat_massage_strength_2_left",
  seat_massage_strength_1_right: "icon-seat_massage_strength_1_right",
  seat_massage_strength_1_left: "icon-seat_massage_strength_1_left",
  seat_massage_right: "icon-seat_massage_right",
  seat_massage_left: "icon-seat_massage_left",
  e_ac: "icon-e_ac",
  seat_comfort_codriver_left: "icon-seat_comfort_codriver_left",
  seat_comfort_left: "icon-seat_comfort_left",
  seat_config_codriver_left: "icon-seat_config_codriver_left",
  seat_easy_entry_left: "icon-seat_easy_entry_left",
  cityseeker_skyline: "icon-cityseeker_skyline",
  person_login: "icon-person_login",
  car_limousine_hood: "icon-car_limousine_hood",
  car_sport_trunk_close_arrow: "icon-car_sport_trunk_close_arrow",
  car_sport_trunk_open_arrow: "icon-car_sport_trunk_open_arrow",
  plug_ccs_type2: "icon-plug_ccs_type2",
  plug_chademo: "icon-plug_chademo",
  plug_iec_type1: "icon-plug_iec_type1",
  plug_iec_type2: "icon-plug_iec_type2",
  plug_ccs_type_1: "icon-plug_ccs_type_1",
  plug_gbt_20234_2_cn: "icon-plug_gbt_20234_2_cn",
  plug_gbt_20234_3_cn: "icon-plug_gbt_20234_3_cn",
  stopover_flag_left_with_lable: "icon-stopover_flag_left_with_lable",
  stopover_flag_right_label: "icon-stopover_flag_right_label",
  "04_base_layer_Square": "icon-04_base_layer_Square",
  home_sync: "icon-home_sync",
  logo_yelp: "icon-logo_yelp",
  phone_switch_call: "icon-phone_switch_call",
  rating_cityseeker_0_5: "icon-rating_cityseeker_0_5",
  rating_cityseeker_0: "icon-rating_cityseeker_0",
  rating_cityseeker_1_5: "icon-rating_cityseeker_1_5",
  rating_cityseeker_1: "icon-rating_cityseeker_1",
  rating_cityseeker_2_5: "icon-rating_cityseeker_2_5",
  rating_cityseeker_2: "icon-rating_cityseeker_2",
  rating_cityseeker_3_5: "icon-rating_cityseeker_3_5",
  rating_cityseeker_3: "icon-rating_cityseeker_3",
  rating_cityseeker_3star_0_5: "icon-rating_cityseeker_3star_0_5",
  rating_cityseeker_3star_0: "icon-rating_cityseeker_3star_0",
  rating_cityseeker_3star_1_5: "icon-rating_cityseeker_3star_1_5",
  rating_cityseeker_3star_1: "icon-rating_cityseeker_3star_1",
  rating_cityseeker_3star_2_5: "icon-rating_cityseeker_3star_2_5",
  rating_cityseeker_3star_2: "icon-rating_cityseeker_3star_2",
  rating_cityseeker_3star_3: "icon-rating_cityseeker_3star_3",
  rating_cityseeker_4_5: "icon-rating_cityseeker_4_5",
  rating_cityseeker_4: "icon-rating_cityseeker_4",
  rating_cityseeker_5: "icon-rating_cityseeker_5",
  rating_yelp_0: "icon-rating_yelp_0",
  rating_yelp_1_5: "icon-rating_yelp_1_5",
  rating_yelp_1: "icon-rating_yelp_1",
  rating_yelp_2_5: "icon-rating_yelp_2_5",
  rating_yelp_2: "icon-rating_yelp_2",
  rating_yelp_3_5: "icon-rating_yelp_3_5",
  rating_yelp_3: "icon-rating_yelp_3",
  rating_yelp_4_5: "icon-rating_yelp_4_5",
  rating_yelp_4: "icon-rating_yelp_4",
  rating_yelp_5: "icon-rating_yelp_5",
  sxm_possession: "icon-sxm_possession",
  parking_brake_na: "icon-parking_brake_na",
  lgi_aquaplaning: "icon-lgi_aquaplaning",
  traffic_antenna: "icon-traffic_antenna",
  traffic_sign_danger_eu: "icon-traffic_sign_danger_eu",
  logo_hd_radio: "icon-logo_hd_radio",
  globe_na: "icon-globe_na",
  news: "icon-news",
  flight_info: "icon-flight_info",
  city_events: "icon-city_events",
  privacy_data: "icon-privacy_data",
  privacy_data_tracking: "icon-privacy_data_tracking",
  privacy_data_gps: "icon-privacy_data_gps",
  data_tracking: "icon-data_tracking",
  wheel: "icon-wheel",
  arrow_n_button: "icon-arrow_n_button",
  globe_small_na: "icon-globe_small_na",
  globe_small: "icon-globe_small",
  sxm_notification_outline: "icon-sxm_notification_outline",
  sxm_for_you: "icon-sxm_for_you",
  sxm_notification: "icon-sxm_notification",
  sxm_on_demand: "icon-sxm_on_demand",
  apple_add_to_library: "icon-apple_add_to_library",
  apple_dislike: "icon-apple_dislike",
  apple_for_you: "icon-apple_for_you",
  apple_love: "icon-apple_love",
  apple_radio: "icon-apple_radio",
  skip_30s_backward: "icon-skip_30s_backward",
  skip_30s_forward: "icon-skip_30s_forward",
  logo_apple_music: "icon-logo_apple_music",
  logo_apple_podcasts: "icon-logo_apple_podcasts",
  apple_remove_from_playlist: "icon-apple_remove_from_playlist",
  apple_play_next: "icon-apple_play_next",
  apple_play_later: "icon-apple_play_later",
  apple_lyrics: "icon-apple_lyrics",
  apple_library: "icon-apple_library",
  apple_create_station: "icon-apple_create_station",
  apple_add_to_playlist: "icon-apple_add_to_playlist",
  qr_code_na: "icon-qr_code_na",
  bubble_recommendations: "icon-bubble_recommendations",
  charging_target_state: "icon-charging_target_state",
  charging_profile: "icon-charging_profile",
  swipe: "icon-swipe",
  phone_ring_tone: "icon-phone_ring_tone",
  parking_forward: "icon-parking_forward",
  filler_cap_mechanic_warning_right: "icon-filler_cap_mechanic_warning_right",
  filler_cap_mechanic_warning_left: "icon-filler_cap_mechanic_warning_left",
  plug_small: "icon-plug_small",
  logo_feedly: "icon-logo_feedly",
  e_range_nav: "icon-e_range_nav",
  box_checked_empty: "icon-box_checked_empty",
  apple_explicit: "icon-apple_explicit",
  rear_window_defrost: "icon-rear_window_defrost",
  front_window_defrost: "icon-front_window_defrost",
  skip_15s_backward: "icon-skip_15s_backward",
  skip_15s_forward: "icon-skip_15s_forward",
  traffic_sign_speed_limit_advisory_kmh_nar_can: "icon-traffic_sign_speed_limit_advisory_kmh_nar_can",
  traffic_sign_speed_limit_kmh_nar_can: "icon-traffic_sign_speed_limit_kmh_nar_can",
  e_range_cn: "icon-e_range_cn",
  sxm_fast_backward: "icon-sxm_fast_backward",
  sxm_fast_forward: "icon-sxm_fast_forward",
  traffic_sign_speed_limit_eu_kmh: "icon-traffic_sign_speed_limit_eu_kmh",
  traffic_sign_speed_limit_eu_mph: "icon-traffic_sign_speed_limit_eu_mph",
  traffic_sign_speed_limit_generic_nar_can: "icon-traffic_sign_speed_limit_generic_nar_can",
  lg_special_carpool: "icon-lg_special_carpool",
  logo_apple_music_color: "icon-logo_apple_music_color",
  connect: "icon-connect",
  export: "icon-export",
  news_channel: "icon-news_channel",
  eye: "icon-eye",
  eye_off: "icon-eye_off",
  subject: "icon-subject",
  homelink_send_1: "icon-homelink_send_1",
  homelink_send_2: "icon-homelink_send_2",
  homelink_send_3: "icon-homelink_send_3",
  trip: "icon-trip",
  traffic_info_outline_ece: "icon-traffic_info_outline_ece",
  traffic_info_outline_nar: "icon-traffic_info_outline_nar",
  air: "icon-air",
  base_mode_menu: "icon-base_mode_menu",
  tune_keypads: "icon-tune_keypads",
  powermeter: "icon-powermeter",
  porsche_labs: "icon-porsche_labs",
  lg_2s_dr_40_mitte_kurz_n: "icon-lg_2s_dr_40_mitte_kurz_n",
  lg_2s_dr_60_mitte_n: "icon-lg_2s_dr_60_mitte_n",
  lg_2s_dr_a0_mitte_n: "icon-lg_2s_dr_a0_mitte_n",
  lg_2s_dr_c0_mitte_kurz_n: "icon-lg_2s_dr_c0_mitte_kurz_n",
  lg_3s_dr_20_rechts_lang_n: "icon-lg_3s_dr_20_rechts_lang_n",
  lg_3s_dr_20_rechts_n: "icon-lg_3s_dr_20_rechts_n",
  lg_3s_dr_40_rechts_kurz_n: "icon-lg_3s_dr_40_rechts_kurz_n",
  lg_3s_dr_40_rechts_lang_n: "icon-lg_3s_dr_40_rechts_lang_n",
  lg_3s_dr_40_rechts_n: "icon-lg_3s_dr_40_rechts_n",
  lg_3s_dr_c0_links_kurz_n: "icon-lg_3s_dr_c0_links_kurz_n",
  lg_3s_dr_c0_links_lang_n: "icon-lg_3s_dr_c0_links_lang_n",
  lg_3s_dr_c0_links_n: "icon-lg_3s_dr_c0_links_n",
  lg_3s_dr_e0_links_lang_n: "icon-lg_3s_dr_e0_links_lang_n",
  lg_3s_dr_e0_links_n: "icon-lg_3s_dr_e0_links_n",
  lg_dr_40_n: "icon-lg_dr_40_n",
  lg_dr_60_n: "icon-lg_dr_60_n",
  lg_dr_a0_n: "icon-lg_dr_a0_n",
  lg_dr_c0_n: "icon-lg_dr_c0_n",
  option_view_1: "icon-option_view_1",
  // Webapp IconTypes
  app_img: "big",
  app_svg: "svg",
} as const
