import config from "../../../config.json"
import definitions from "@pag/center/views/settingsScreen/definitions"
import { tiles } from "@pag/center/components/launcher/launcherTiles"
import { LauncherTile } from "@pag/center/views/launcherConfig/types"

const msInKmh = 5 / 18
const kmhToMs = (num: number): number => num * msInKmh

const initial = Object.create(null)

const lang: string = localStorage.getItem("locale") || "de_DE"

// @NOTE(kirill): We use enrichers to unserialise configs. For instance runtime
// uses m/s to store speed values, but config allows us to use km/s, which is
// more convenient. For those values we use m/s to km/h convierting function.

const settings_enricher: { [index: string]: any } = {
    Calendar_Settings_StartWeek: definitions.calendarWeekStart,
    Settings_AssistanceSystems_AdaptiveSpeedLimiter_AdoptionOfSpeedlimits: definitions.assistanceSpeedLimitsAdoption,
    Settings_AssistanceSystems_AdaptiveSpeedLimiter_MaximumSpeed: kmhToMs,
    Settings_AssistanceSystems_LaneChangeAssist_Brightness: definitions.assistanceLaneChangeBrightness,
    Settings_AssistanceSystems_LaneDepartureWarning_Volume: definitions.assistanceLaneDepartureWarning,
    Settings_AssistanceSystems_NightViewAssist_WarningTime: definitions.assistanceWarningTime,
    Settings_AssistanceSystems_ParkAssist_Volume: definitions.assistanceParkAssistVolume,
    Settings_AssistanceSystems_PorscheInnoDrive_MaxSpeed: kmhToMs,
    Settings_AssistanceSystems_TrafficSignRecognition_Exceed: kmhToMs,
    Settings_AssistanceSystems_WarnAndBrakeAssist_CollisionWarning: definitions.assistanceCollisionWarning,
    Settings_AssistanceSystems_WarnAndBrakeAssist_DistanceWarning: definitions.assistanceDistanceWarning,
    Settings_Car_Trip_ConfigureTripPersonally_line1: definitions.tripPersonalConfig,
    Settings_Car_Trip_ConfigureTripPersonally_line2: definitions.tripPersonalConfig,
    Settings_Car_Trip_ConfigureTripPersonally_line3: definitions.tripPersonalConfig,
    Settings_Car_Trip_ConfigureTripPersonally_line4: definitions.tripPersonalConfig,
    Settings_Display_CenterConsoleOperatingPanel_FocusMovementSpeed: definitions.focusMovementSpeed,
    Settings_Display_CenterConsoleOperatingPanel_HapticFeedback: definitions.hapticFeedback,
    Settings_Display_CenterConsoleOperatingPanel_TouchTones: definitions.touchTones,
    Settings_Display_CentralDisplay_TouchTones: definitions.touchTones,
    Settings_Display_HUD_Custom_Adjust_MainArea: definitions.hudCustomAdjustMainArea,
    Settings_Display_HUD_DayAndNightView: definitions.hudDayNightView,
    Settings_Display_HUD_IncomingCalls: definitions.hudIncomingCalls,
    Settings_Display_HUD_TrafficSign_Deviation: kmhToMs,
    Settings_Display_HUD_View: definitions.hudView,
    Settings_Display_InstrumentCluster_ConfigureDisplayScope: definitions.displayScope,
    Settings_Display_InstrumentCluster_CustomisedView_Line1: definitions.displayCustomizedView,
    Settings_Display_InstrumentCluster_CustomisedView_Line2: definitions.displayCustomizedView,
    Settings_Display_InstrumentCluster_CustomisedView_Line3: definitions.displayCustomizedView,
    Settings_Display_InstrumentCluster_CustomisedView_Line4: definitions.displayCustomizedView,
    Settings_Display_InstrumentCluster_PersonalSpeedLimits_SpeedLimit1: [ null, kmhToMs ],
    Settings_Display_InstrumentCluster_PersonalSpeedLimits_SpeedLimit2: [ null, kmhToMs ],
    Settings_Display_InstrumentCluster_TrafficSignRecognition_Deviation: kmhToMs,
    Settings_Media_AdjustSortingOfSources: definitions.mediaSources,
    Settings_Navigation_NavigationSettings_MapSettings_DayAndNightView: definitions.navigationDayAndNightView,
    Settings_NotificationCenter_Allow: definitions.notificationCenterAllow,
    Settings_Phone_PhoneBookSettings_Criteria: definitions.phoneBookSortingCriteria,
    Settings_PrivacyAndPorscheConnect_AnonData: definitions.privacyModes,
    Settings_PrivacyAndPorscheConnect_DataFromPorsche: definitions.privacyModes,
    Settings_PrivacyAndPorscheConnect_Improvements: definitions.privacyModes,
    Settings_PrivacyAndPorscheConnect_ConsentData: definitions.privacyModes,
    Settings_PrivacyAndPorscheConnect_PrivateMode: definitions.privacyModes,
    Settings_PrivacyAndPorscheConnect_ServiceWithTracking: definitions.privacyModes,
    Settings_PrivacyAndPorscheConnect_ServicesWithTracking: definitions.privacyModes,
    Settings_PrivacyAndPorscheConnect_ServicesWithoutTracking: definitions.privacyModes,
    Settings_System_DateAndTime_SetDateFormat: definitions.dateFormats,
    Settings_System_DateAndTime_SetTimeFormat: definitions.timeFormats,
    Settings_System_DateAndTime_SetTimeZoneManually: definitions.timezones,
    Settings_System_TimeAndTemperature: definitions.timeTemp,
    Settings_System_Units_Charging: definitions.chargeSpeedUnits,
    Settings_System_Units_Consumption: definitions.consumptionUnits,
    Settings_System_Units_Distance: definitions.distanceUnits,
    Settings_System_Units_Pressure: definitions.pressureUnits,
    Settings_System_Units_Speed: definitions.speedUnits,
    Settings_System_Units_SpeedAndDistance: definitions.speedAndDistanceUnits,
    Settings_System_Units_Temperature: definitions.temperatureUnits,
    Settings_Vehicle_ShortcutButtons_ClusterButton: definitions.vehicleShortcutButtons,
    Settings_Vehicle_ShortcutButtons_SteeringWheel: definitions.vehicleShortcutButtons,
    Settings_Vehicle_TyrePressureMonitoring_Selection: definitions.vehicleTyrePressureSelection,
    Settings_Vehicle_VehicleLockingSystems_DoorUnlocking: definitions.vehicleDoorUnlocking,
    Settings_Volume_TouchTones: definitions.touchTones
}

if (config.settings !== undefined) {
    initial.settings = Object.create(null)
    const source = config.settings as { [index: string]: any }

    Object.keys(source).forEach(function (key) {
        if (source[key] === null) {
            return;
        }

        switch (typeof settings_enricher[key]) {

        case "object":
            initial.settings[key] = (
                Array.isArray(settings_enricher[key])
                ? (
                    Array.isArray(source[key])
                    ? Object.freeze(source[key].map((label: string, index: number) => (
                        settings_enricher[key].find(
                            (def: { [index: string]: string }) => (
                                def !== null &&
                                def.label === label
                            )
                        ) ||
                        (
                            typeof settings_enricher[key][index] === "function"
                            ? settings_enricher[key][index](source[key][index])
                            : source[key][index]
                        )
                    )))
                    : settings_enricher[key].find(
                        (def: { [index: string]: string }) => (
                            def.label === source[key]
                        )
                    )
                )
                : source[key]
            )
            break

        case "function":
            initial.settings[key] = settings_enricher[key](source[key])
            break

        default:
            initial.settings[key] = source[key]
        }
    })

    // @NOTE(kirill): These properties are generated on runtime. Maybe apart
    // from timezone, but it’s not translated so we don’t have a reference 
    // string for it.

    initial.settings.Settings_System_LanguageAndKeyboard_Language = definitions.langs.find((lang_def) => lang_def.value === lang)
    initial.settings.Settings_System_LanguageAndKeyboard_Keyboard = Object.freeze([ definitions.keyboard_langs.find((lang_def) => lang_def.value === lang) ])
    initial.settings.Settings_System_DateAndTime_SetTimeManually = definitions.today
    initial.settings.Settings_System_DateAndTime_SetDateManually = definitions.today
    initial.settings.Settings_System_DateAndTime_SetTimeZoneManually = definitions.timezones[0]

    Object.freeze(initial.settings)
}

if (config.launcher !== undefined) {
    if (Array.isArray(config.launcher.tiles)) {
        initial.launcher = Object.create(null)
        initial.launcher.tiles = config.launcher.tiles.map((label: string) => tiles.find((tile: LauncherTile) => tile.text === label))

        Object.freeze(initial.launcher.tiles)
        Object.freeze(initial.launcher)
    }
}

if (config.mainTabBarItems !== undefined) {
    if (Array.isArray(config.mainTabBarItems)) {
        initial.mainTabBarItems = config.mainTabBarItems.map((label: string) => tiles.find((tile: LauncherTile) => tile.text === label))

        Object.freeze(initial.mainTabBarItems)
    }
}

export default Object.freeze(initial)
