import { Store as ReduxStore, Dispatch as ReduxDispatch } from "redux"

import { IconType } from "@pag/center/components/icon/types"

export const ADD_MEDIA_FAVORITE = "ADD_TO_MEDIA_FAVORITE"
export const TOGGLE_MEDIA_FAVORITE = "TOGGLE_MEDIA_FAVORITE"
export const SET_SONG = "SET_SONG"
export const SET_SONG_NEXT = "SET_SONG_NEXT"
export const SET_SONG_PREV = "SET_SONG_PREV"
export const PLAY_SONG = "PLAY_SONG"
export const SET_CURRENT_BROWSING_CATEGORY = "SET_CURRENT_BROWSING_CATEGORY"
export const DISABLE_ENTRY_ANIMATIONS = "DISABLE_ENTRY_ANIMATIONS"
export const TRIGGER_LIST_SWITCH_ANIMATION = "TRIGGER_LIST_SWITCH_ANIMATION"
export const SET_ACTIVE_SOURCE = "SET_ACTIVE_SOURCE"
export const SET_PLAYING_SOURCE = "SET_PLAYING_SOURCE"

export type ReduxInitAction = { type: "@@INIT" }

export enum ESourceNames {
  RADIO = "radio",
  SEARCH = "search",
  FAVORITES = "favorites",
  ONLINE_RADIO = "online-radio",
  APPLE_MUSIC = "apple-music",
  APPLE_MUSIC_RADIO = "apple-music-radio",
  APPLE_PODCASTS = "apple-podcasts",
  APPLE_CARPLAY = "apple-carplay",
  ANDROID_AUTO = "android-auto",
  SPOTIFY = "spotify",
  USB = "usb",
  BLUETOOTH = "bluetooth",
  SIRIUS_XM = "sirius_xm",
  MORE = "more"
}

export type List_Entry = {
  label: string
  icon?: IconType
  detail?: List_Entry
  artist?: List_Entry
  album?: List_Entry
  list?: Readonly<List_Entry[]>
  source?: string
  duration?: string
  genre?: string
}

export interface category {
  name: string
  categoryType: string
  label: string
  list?: Readonly<List_Entry[]>
}

export type setSongAction = {
  type: typeof SET_SONG
  song: List_Entry
  source: ESourceNames
  playing: boolean
  listFilter: string
}

export type setSongNextAction = {
  type: typeof SET_SONG_NEXT
  source: ESourceNames
  playing: boolean
  listFilter: string
}

export type setSongPrevAction = {
  type: typeof SET_SONG_PREV
  source: ESourceNames
  playing: boolean
  listFilter: string
}

export type toggleMediaFavoriteAction = {
  type: typeof TOGGLE_MEDIA_FAVORITE
  favorite: List_Entry
}

export type addMediaFavoriteAction = {
  type: typeof ADD_MEDIA_FAVORITE
  favorite: List_Entry
}

export type playSongAction = {
  type: typeof PLAY_SONG
  song: List_Entry
  source: ESourceNames
  playing: boolean
}

export type setCurrentBrowsingCategoryAction = {
  type: typeof SET_CURRENT_BROWSING_CATEGORY
  category: category
}

export type disableEntryAnimationsAction = {
  type: typeof DISABLE_ENTRY_ANIMATIONS
  disabled: boolean
}

export type trigglerListSwitchAnimationAction = {
  type: typeof TRIGGER_LIST_SWITCH_ANIMATION
  animation: boolean
}

export type setActiveSourceAction = {
  type: typeof SET_ACTIVE_SOURCE
  activeSource: string
}

export type setPlayingSourceAction = {
  type: typeof SET_PLAYING_SOURCE
  playingSource: string
}

type sourceState = {
   songs: Readonly<List_Entry[]>,
   selected: List_Entry
}

// NOTE(kirill): the sources that use sourceState type should be moved under
// “sources” namespace to better define this state type

export type audioPlayerState = {
  [index: string]: any
  favorites: sourceState
  usb: sourceState
  radio: sourceState
  "online-radio": sourceState
  "apple-podcasts": sourceState
  playing: boolean
  entryAnimationsDisabled: boolean
  listFilter: string
  listSwitchAnimation: boolean
  activeSource: string
  playingSource: string
}

export type Action =
  | SongActionType
  | ReduxInitAction
  | addMediaFavoriteAction
  | toggleMediaFavoriteAction
  | setSongAction
  | setSongNextAction
  | setSongPrevAction
  | playSongAction
  | disableEntryAnimationsAction
  | setCurrentBrowsingCategoryAction
  | trigglerListSwitchAnimationAction
  | setActiveSourceAction
  | setPlayingSourceAction

export type Store = ReduxStore<audioPlayerState, Action>

export type Dispatch = ReduxDispatch<Action>

export type SongActionType = setSongAction | playSongAction
