export enum UnitKind {
  None,
  Charge,
  Consumption,
  Date,
  Distance,
  Duration,
  Pressure,
  Speed,
  Temperature,
  Time
}
