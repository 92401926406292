import { createStore, combineReducers } from "redux"
import { activeFilterReducer } from "@pag/center/components/quickFilter/quickFilterIconWithIndicator/reducer"
import {
  locationHistoryReducer,
  homeButtonUrlReducer,
  pageTransitionAnimationReducer,
  socReducer,
  incomingCallPopupVisibilityReducer,
  voiceSearchReducer,
  popupReducer,
  overlayReducer
} from "@pag/center/views/centerMainScreen/reducer"
import { activeSubTabItemReducer } from "@pag/center/components/subTabBarRouting/subTabBarRoutingItem/reducer"
import { songReducer } from "@pag/center/components/player/audioPlayer/reducer"
import { titlePlayTimeReducer } from "@pag/center/components/player/audioPlayer/html5AudioWrapper/reducer"
import { searchBarInputReducer } from "@pag/center/components/speller/keyboard/reducer"
import { activeNotificationReducer } from "@pag/center/views/notificationCenter/reducer"
import { closeWizardReducer, mainTabBarItemsReducer } from "@pag/center/components/mainTabBar/reducer"
import { driveMainScreenListItemReducer, driveModeReducer } from "@pag/center/views/carScreen/driveMainScreen/reducer"
import { MainStateReducer } from "../../redux/reducer/mainState/reducer"
import { navigationReducer, mapReducer, poiReducer } from "@pag/center/views/navigationScreen/mapMain/reducer"
import { statusBarSettingsReducer } from "@pag/center/components/statusBar/reducer"
import { mediaSoundReducer } from "@pag/center/views/mediaPlayer/mediaSound/reducer"
import { playbackFastForwarReducer } from "@pag/center/components/player/audioPlayer/audioPlayerProgressBar/reducer"
import { welcomeWizardReducer } from "@pag/center/views/welcomeScreen/reducer"
import { settingsReducer, settingsScreenReducer } from "@pag/center/views/settingsScreen/reducer"
import { myScreenReducer } from "@pag/center/views/myScreen/reducer"
import { destInputReducer } from "@pag/center/views/navigationScreen/reducer"
import { homeLinkReducer } from "@pag/center/views/homeLinkScreen/reducer"
import { phoneSearchReducer, phoneScreenReducer } from "@pag/center/views/phoneScreen/reducer"
import { mediaReducer } from "@pag/center/views/mediaPlayer/reducer"
import { launcherTileReducer } from "@pag/center/views/launcherConfig/reducer"
import { chargingScreenReducer } from "@pag/center/views/chargingScreen/reducer"
import { accountsReducer } from "@pag/center/views/accountsScreen/reducer"
import parkingReducer from "@pag/center/views/parkingAssist/reducer"
import deviceReducer from "@pag/center/views/deviceScreen/reducer"
import climateReducer from "@pag/center/views/climateScreen/reducer"

const rootReducer = combineReducers({
  mainState: MainStateReducer,
  quickFilterActive: activeFilterReducer,
  subTabBarItem: activeSubTabItemReducer,
  audioPlayer: songReducer,
  titlePlayTime: titlePlayTimeReducer,
  locationHistory: locationHistoryReducer,
  homeButtonUrl: homeButtonUrlReducer,
  pageAnimationTrasition: pageTransitionAnimationReducer,
  searchBarInput: searchBarInputReducer,
  activeNotification: activeNotificationReducer,
  climate: climateReducer,
  device: deviceReducer,
  driveMainScreenListItem: driveMainScreenListItemReducer,
  mainTabBarItems: mainTabBarItemsReducer,
  closeWizard: closeWizardReducer,
  navigation: navigationReducer,
  map: mapReducer,
  poi: poiReducer,
  statusBarSettings: statusBarSettingsReducer,
  mediaSound: mediaSoundReducer,
  driveMode: driveModeReducer,
  soc: socReducer,
  songFastForwarTime: playbackFastForwarReducer,
  welcomeWizard: welcomeWizardReducer,
  incomingCallPopupVisibility: incomingCallPopupVisibilityReducer,
  settingsScreen: settingsScreenReducer,
  accounts: accountsReducer,
  settings: settingsReducer,
  myScreen: myScreenReducer,
  voiceSearch: voiceSearchReducer,
  destInput: destInputReducer,
  homeLink: homeLinkReducer,
  phoneSearch: phoneSearchReducer,
  phoneScreen: phoneScreenReducer,
  parkingAssist: parkingReducer,
  media: mediaReducer,
  launcher: launcherTileReducer,
  charging: chargingScreenReducer,
  popup: popupReducer,
  overlay: overlayReducer
})

export type AppState = ReturnType<typeof rootReducer>

export default function configureStore() {
  const store = createStore(
    rootReducer,
    (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__()
  )

  return store
}
