import React from "react"
import cx from "classnames"

import { IconType } from "./types"

import "./icon.scss"

interface IIcon {
  iconType?: IconType
  iconClass?: string
  iconUrl?: string
  isPressed?: boolean
  style?: any
  disabled?: boolean
  onPress?: any
}

const Icon: React.FunctionComponent<IIcon> = ({
  iconType,
  iconClass,
  iconUrl,
  isPressed,
  style,
  disabled,
  onPress
}) => (
  <div
    onClick={onPress !== undefined ? onPress : () => {}}
    style={style}
    className={cx(
      "icon",
      iconType,
      iconClass,
      {
        "state-pressed": isPressed,
        "state-disabled": disabled
      }
    )}
  >
    {iconUrl && <img alt="" className="icon__url" src={iconUrl} />}
  </div>
)

export default Icon
