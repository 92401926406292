import React from "react"
import cx from "classnames"
import { useTranslation } from "react-i18next"

import ownTranslationsDe from "../../translations/no_translation_de.json"
import ownTranslationsEn from "../../translations/no_translation_en.json"

import "./i18nLabel.scss"

interface Ii18nLabel {
  text: string | TemplateStringsArray
  className?: string
  id?: string
  nonI18n?: boolean
  interpolationKeys?: any
}

const I18nLabel: React.FunctionComponent<Ii18nLabel> = ({
  text,
  className,
  id,
  nonI18n,
  interpolationKeys
}) => {
  const { t, i18n } = useTranslation("center")

  const lng = localStorage.getItem("locale")

  const getLabel = () => {
    let label: string | TemplateStringsArray = ""

    //@ts-ignore
    if (lng === "de_DE" && ownTranslationsDe[text]) {
      // @ts-ignore
      label = ownTranslationsDe[text]
      // @ts-ignore
    } else if (ownTranslationsEn[text]) {
      // @ts-ignore
      label = ownTranslationsEn[text]
    } else {
      label = text
    }

    let exists = false
    if (label !== undefined) {
      try {
        // @ts-ignore
        exists = i18n.exists(label)
      } catch (ignore) {}
    }

    return (
      <div id={id} className={cx("i18nLabel", className)}>
        <span className="label__span">{exists ? t(label, interpolationKeys) : label}</span>
      </div>
    )
  }

  return getLabel()
}

export default I18nLabel
