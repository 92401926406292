import android_auto from "@pag/center/assets/icon-master/home/logo_android_auto_app_tile.svg"
import apple_carplay from "@pag/center/assets/icon-master/home/apple_carplay_app_tile.svg"
import breakdown_call from "@pag/center/assets/icon-master/home/road_side_assistance_app_tile.svg"
import calendar from "@pag/center/assets/icon-master/home/organizer_app_tile.svg"
import car from "@pag/center/assets/icon-master/home/car_front_J1_app_tile.svg"
import devices from "@pag/center/assets/icon-master/home/cell_phone_app_tile.svg"
import charge from "@pag/center/assets/icon-master/home/charging_active_app_tile.svg"
import city_event from "@pag/center/assets/icon-master/home/city_events_app_tile.svg"
import climate from "@pag/center/assets/icon-master/home/climate_app_tile.svg"
import codriver from "@pag/center/assets/icon-master/home/codriver_cockpit_app_tile.svg"
import concierge from "@pag/center/assets/icon-master/home/concierge_app_tile.svg"
import contacts from "@pag/center/assets/icon-master/home/icon-contacts.svg"
import ETC from "@pag/center/assets/icon-master/home/ETC_app_tile.svg"
import flights from "@pag/center/assets/icon-master/home/flights_app_tile.svg"
import home from "@pag/center/assets/icon-master/home/home_app_tile_default.svg"
import homelink from "@pag/center/assets/icon-master/home/homelink_app_tile.svg"
import information from "@pag/center/assets/icon-master/home/info_outline_app_tile.svg"
import maintenance from "@pag/center/assets/icon-master/home/predictive_maintenance_J1_app_tile.svg"
import manual from "@pag/center/assets/icon-master/home/manual_app_tile.svg"
import media from "@pag/center/assets/icon-master/home/note_app_tile.svg"
import navigation from "@pag/center/assets/icon-master/home/north_arrow_app_tile.svg"
import news from "@pag/center/assets/icon-master/home/news_app_tile.svg"
import notifications from "@pag/center/assets/icon-master/home/notification_app_tile.svg"
import parking from "@pag/center/assets/icon-master/home/parking_app_tile.svg"
import phone from "@pag/center/assets/icon-master/home/phone_receiver_app_tile.svg"
import settings from "@pag/center/assets/icon-master/home/settings_app_tile.svg"
import sport_chrono from "@pag/center/assets/icon-master/home/timer_app_tile.svg"
import updates from "@pag/center/assets/icon-master/home/updates_app_tile.svg"
import weather from "@pag/center/assets/icon-master/home/weather_app_tile.svg"

export default Object.freeze({
  android_auto,
  apple_carplay,
  breakdown_call,
  calendar,
  car,
  charge,
  city_event,
  climate,
  codriver,
  concierge,
  contacts,
  devices,
  ETC,
  flights,
  home,
  homelink,
  information,
  maintenance,
  manual,
  media,
  navigation,
  news,
  notifications,
  parking,
  phone,
  settings,
  sport_chrono,
  updates,
  weather
})
