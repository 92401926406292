import { LauncherTileState, LauncherTileActionType, UPDATE_LAUNCHER_TILE_ORDER } from "./types"
import initial from "@pag/center/config/initial"

const launcherTileInitialState: LauncherTileState = {
  tiles: initial.launcher.tiles.slice()
}

export function launcherTileReducer(
  state = launcherTileInitialState,
  action: LauncherTileActionType
): LauncherTileState {
  switch (action.type) {
    case UPDATE_LAUNCHER_TILE_ORDER:
      return {
        ...state,
        tiles: action.payload.tiles
      }
    default:
      return state
  }
}
