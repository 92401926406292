import {
  settingsScreenState,
  SET_VISIBLE_SETTINGS_LIST,
  SET_CURRENT_RADIO_BUTTON_LIST_ITEM,
  Action,
  UPDATE_DETAIL_SCREEN_OVERLAY_VISIBLE,
  settingsState,
  setPreferenceValueAction,
  SET_PREFERENCE_VALUE,
} from "./types"
import lists from "@pag/center/views/settingsScreen/listContents/settingsListContents"
import settingDefs from "./definitions"
import initial from "@pag/center/config/initial"

export function settingsScreenReducer(
  state: settingsScreenState = {
    visibleList: lists.settings_MainList,
    currentRadioButtonListItem: "",
    detailsScreenOverlayVisible: false
  },
  action: Action
): settingsScreenState {
  switch (action.type) {
    case SET_VISIBLE_SETTINGS_LIST:
      return { ...state, visibleList: action.visibleList }
    case SET_CURRENT_RADIO_BUTTON_LIST_ITEM:
      return { ...state, currentRadioButtonListItem: action.itemName }
    case UPDATE_DETAIL_SCREEN_OVERLAY_VISIBLE:
      return { ...state, detailsScreenOverlayVisible: action.visible }
    default:
      return state
  }
}

export function settingsReducer(
  state: settingsState = Object.freeze(Object.assign({}, initial.settings)),
  action: setPreferenceValueAction
): settingsState {
  switch (action.type) {

    case SET_PREFERENCE_VALUE:
    if (
      action.key === "Settings_System_DateAndTime_SetTimeManually" ||
      action.key === "Settings_System_DateAndTime_SetDateManually"
    ) {
      const new_value = new Date(action.value)
      return Object.assign(
        {},
        state,
        {
          Settings_System_DateAndTime_SetTimeManually: new_value,
          Settings_System_DateAndTime_SetDateManually: new_value
        }
      )
    } else if (action.key === "Settings_System_Units_SpeedAndDistance") {
      const index = settingDefs.speedAndDistanceUnits.indexOf(action.value)
      return Object.assign(
        {},
        state,
        {
          [action.key]: action.value,
          Settings_System_Units_Speed: settingDefs.speedUnits[index],
          Settings_System_Units_Distance: settingDefs.distanceUnits[index],
          Settings_System_Units_Charging: settingDefs.chargeSpeedUnits[index]
        }
      )
    } else {
      return Object.assign({}, state, { [action.key]: action.value })
    }

    default:
    return state
  }
}
