import {
  Action,
  audioPlayerState,
  SET_SONG,
  SET_SONG_NEXT,
  SET_SONG_PREV,
  PLAY_SONG,
  DISABLE_ENTRY_ANIMATIONS,
  ADD_MEDIA_FAVORITE,
  TOGGLE_MEDIA_FAVORITE,
  SET_CURRENT_BROWSING_CATEGORY,
  TRIGGER_LIST_SWITCH_ANIMATION,
  SET_ACTIVE_SOURCE,
  SET_PLAYING_SOURCE,
  ESourceNames,
  List_Entry
} from "@pag/center/components/player/audioPlayer/types"
import podcasts_overview, { songs as podcasts } from "@pag/center/components/player/audioPlayer/lists/podcasts"
import radio_lists from "@pag/center/components/player/audioPlayer/lists/radio"
import spotify_overview, { songs as spotify_songs } from "@pag/center/components/player/audioPlayer/lists/spotify"
import usb_overview, { songs as usb_songs } from "@pag/center/components/player/audioPlayer/lists/usb"
import apple_music_overview from "@pag/center/components/player/audioPlayer/lists/appleMusic"
import { EVisibleListSiriusXM } from "@pag/center/views/mediaPlayer/sourceLists/helper"

const overviewProps = Object.freeze({
  categoryType: "Overview",
  label: "Overview"
})

const initialState: audioPlayerState = {
  [ESourceNames.USB]: {
    songs: usb_songs.slice(),
    selected: usb_songs[0]
  },
  [ESourceNames.BLUETOOTH]: {
    songs: usb_songs.slice(),
    selected: usb_songs[0]
  },
  [ESourceNames.FAVORITES]: {
    songs: radio_lists.favorites,
    selected: radio_lists.favorites[0]
  },
  [ESourceNames.RADIO]: {
    songs: radio_lists.radio.slice(),
    selected: radio_lists.radio[0]
  },
  [ESourceNames.ONLINE_RADIO]: {
    songs: radio_lists.onlineRadio.slice(),
    selected: radio_lists.onlineRadio[0]
  },
  [ESourceNames.APPLE_PODCASTS]: {
    songs: podcasts,
    selected: podcasts[0]
  },
  [ESourceNames.SPOTIFY]: {
    songs: spotify_songs.slice(),
    selected: spotify_songs[0]
  },
  [ESourceNames.SIRIUS_XM]: {
    songs: usb_songs.slice(),
    selected: usb_songs[0]
  },
  [ESourceNames.APPLE_MUSIC]: {
    songs: usb_songs.slice(),
    selected: usb_songs[0]
  },
  [ESourceNames.APPLE_CARPLAY]: {
    songs: usb_songs.slice(0, 1),
    selected: usb_songs[0]
  },
  [ESourceNames.ANDROID_AUTO]: {
    songs: usb_songs.slice(0, 1),
    selected: usb_songs[0]
  },
  playing: false,
  browsingPath: [],
  entryAnimationsDisabled: false,
  listFilter: "",
  listSwitchAnimation: false,
  activeSource: ESourceNames.ONLINE_RADIO,
  playingSource: ESourceNames.ONLINE_RADIO
}

export function songReducer(
  state = initialState,
  action: Action
): audioPlayerState {
  switch (action.type) {

    case ADD_MEDIA_FAVORITE:
      if (state[ESourceNames.FAVORITES].songs.includes(action.favorite)) {
        return { ...state }
      } else {
        return {
          ...state,
          [ESourceNames.FAVORITES]: {
            songs: [
              ...state[ESourceNames.FAVORITES].songs,
              action.favorite
            ],
            selected: action.favorite
          }
        }
      }

    case TOGGLE_MEDIA_FAVORITE:
      if (state[ESourceNames.FAVORITES].songs.includes(action.favorite)) {
        return {
          ...state,
          [ESourceNames.FAVORITES]: {
            ...state[ESourceNames.FAVORITES],
            songs: state[ESourceNames.FAVORITES].songs.filter((song: List_Entry) => song !== action.favorite)
          }
        }
      } else {
        return {
          ...state,
          [ESourceNames.FAVORITES]: {
            ...state[ESourceNames.FAVORITES],
            songs: [
              ...state[ESourceNames.FAVORITES].songs,
              action.favorite
            ]
          }
        }
      }

    case SET_SONG:
      return {
        ...state,
        [action.source]: {
          ...state[action.source],
          selected: state[action.source].songs.find((song: List_Entry) => action.song === song) || state[action.source].songs[0],
        },
        playing: action.playing,
        listFilter: action.listFilter
      }

    case SET_SONG_NEXT: {
      const list = state[action.source].songs
      const nextIndex = list.indexOf(state[action.source].selected) + 1
      return {
        ...state,
        [action.source]: {
          ...state[action.source],
          selected: list[nextIndex] || list[0],
        },
        playing: action.playing,
        listFilter: action.listFilter
      }
    }

    case SET_SONG_PREV: {
      const list = state[action.source].songs
      const prevIndex = list.indexOf(state[action.source].selected) - 1
      return {
        ...state,
        [action.source]: {
          ...state[action.source],
          selected: list[prevIndex] || list[list.length - 1],
        },
        playing: action.playing,
        listFilter: action.listFilter
      }
    }

    case PLAY_SONG:
      return {
        ...state,
        [action.source]: {
          songs: state[action.source].songs,
          selected: action.song
        },
        playing: action.playing
      }

    case SET_CURRENT_BROWSING_CATEGORY:
      return {
        ...state,
        browsingPath: (
          action.category
          ? [
            ...state.browsingPath,
            action.category
          ]
          : state.browsingPath.slice(0, -1)
        )
      }

    case DISABLE_ENTRY_ANIMATIONS:
      return {
        ...state,
        entryAnimationsDisabled: action.disabled
      }

    case TRIGGER_LIST_SWITCH_ANIMATION:
      return {
        ...state,
        listSwitchAnimation: action.animation
      }

    case SET_ACTIVE_SOURCE:
      return {
        ...state,
        activeSource: action.activeSource,
        browsingPath: (function () {
          switch (action.activeSource) {
          case ESourceNames.FAVORITES:
            return [ { ...overviewProps, list: state[ESourceNames.FAVORITES].songs } ] 
          case ESourceNames.USB:
          case ESourceNames.BLUETOOTH:
            return [ { ...overviewProps, list: usb_overview } ] 
          case ESourceNames.ONLINE_RADIO:
            return [ { ...overviewProps, list: radio_lists.onlineRadio } ] 
          case ESourceNames.RADIO:
            return [ { ...overviewProps, list: radio_lists.radio } ] 
          case ESourceNames.APPLE_MUSIC:
            return [ { ...overviewProps, list: apple_music_overview } ] 
          case ESourceNames.APPLE_PODCASTS:
            return [ { ...overviewProps, list: podcasts_overview } ] 
          case ESourceNames.SPOTIFY:
            return [ { ...overviewProps, list: spotify_overview } ] 
          case ESourceNames.SIRIUS_XM:
            return [ { ...overviewProps, name: EVisibleListSiriusXM.OVERVIEW_SIRIUS_XM } ] 
          default:
            return []
          }
        }())
      }

    case SET_PLAYING_SOURCE:
      return {
        ...state,
        playingSource: action.playingSource
      }

    default:
      return state
  }
}
