import React, { useEffect, useState } from "react"
import classNames from "classnames"

import { IconType } from "@pag/center/components/icon/types"
import Icon from "@pag/center/components/icon/Icon"
import I18nLabel from "@pag/center/components/I18nLabel/I18nLabel"

import "./button.scss"

interface IButtonProps {
  icon?: IconType
  iconUrl?: string
  labelText?: string
  className?: string
  onClick?(): void
}

const Button: React.FC<IButtonProps> = ({ icon, iconUrl, labelText, className, onClick }) => {
  const [pressed, setPressed] = useState(false)

  useEffect(
    () => {
      if (pressed) {
        const timeout = setTimeout(() => setPressed(false), 500)
        return () => clearTimeout(timeout)
      }
    },
    [ pressed ]
  )

  return (
    <div
      className={classNames("button-wrapper", className, { "state-pressed": pressed })}
      onMouseDown={() => setPressed(!pressed)}
      onMouseUp={() => setPressed(!pressed)}
      onClick={() => {
        onClick && onClick()
        setPressed(true)
      }}
    >
      {/* <div className="animated-bg--growing-circle" /> */}
      <div className="animated-bg--pressed" />
      <div className="button__fading">
        <div className="button__container">
          {icon ? <Icon iconClass="button__icon" iconType={icon} /> : null}
          {iconUrl ? <Icon iconClass="button__icon" iconUrl={iconUrl} /> : null}
          {labelText ? <I18nLabel className="button__text" text={labelText} /> : null}
        </div>
      </div>
    </div>
  )
}

export default React.memo(Button)
