export const UPDATE_LAUNCHER_TILE_ORDER = "UPDATE_LAUNCHER_TILE_ORDER"

export type LauncherTileState = {
  tiles: LauncherTile[]
}

export type LauncherTile = {
  text: string
  url?: string
  icon: string | string[]
  settings_list?: any
  count?: number
}

export type updateLauncherTileOrderAction = {
  type: typeof UPDATE_LAUNCHER_TILE_ORDER
  payload: {
    tiles: LauncherTile[]
  }
}

export type LauncherTileActionType = updateLauncherTileOrderAction
