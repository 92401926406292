import React, { lazy, Suspense } from "react"
import { DndProvider } from "react-dnd"
import { TouchBackend } from "react-dnd-touch-backend"
import { Provider } from "react-redux"
import configureStore from "./components/redux/reducer/index"
import { LoginComponent, renderIfAuthenticated } from "./imgConnection/Helpers"
import { MiniLoader } from "./imgConnection/MiniLoader"
import "./index.css"
import * as serviceWorker from "./serviceWorker"
import { availableLanguages } from "@pag/center/translations/availableLanguages"
import i18n from "./i18n"

const AppContainer = lazy(() => import("./AppContainer"))

const store = configureStore()
export type AppStoreType = typeof store

// eslint-disable-next-line
const getUrl = /^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+)/
const pcmSMW = /pcm-cl([0-9]{2})\.smart-mobility\.info/i;
const exec = getUrl.exec(document.location.href)
const curServer = exec ? exec : ["", "localhost"]
export const srv = curServer[1].toLowerCase()
export const isSMW = srv.indexOf("pcm.smart-mobility.info") >= 0 || pcmSMW.test(srv);
export const isFT = srv.indexOf("pcm.feature-testing.com") >= 0 || srv.indexOf("taycan-pcm.azurewebsites.net") >= 0
export const isLH = srv.indexOf("localhost") >= 0
export const isSL = (
  srv.indexOf("pcm.cxdesignteam.de") !== -1 ||
  srv.indexOf("your-digital-co-pilot.com") !== -1 ||
  srv.indexOf("my-digital-co-pilot.com") !== -1 ||
  srv.indexOf("pcm-experience.com") !== -1
);

// @wip check for exact hostname match by checking on key existence
// before we use it make sure we don’t rely on it (appending “localhost”)
const allowedHostnames = ([
  "localhost",
  "pcm.smart-mobility.info",
  "pcm-cl33.smart-mobility.info",
  "pcm-cl35.smart-mobility.info",
  "pcm-cl37.smart-mobility.info",
  "pcm.feature-testing.com",
  "taycan-pcm.azurewebsites.net",
  "pcm.cxdesignteam.de",
  "your-digital-co-pilot.com",
  "my-digital-co-pilot.com",
  "pcm-experience.com"
]).reduce((acc, curr) => {
  acc[curr] = true
  return acc
}, Object.create(null))

if (isSMW || isFT || isSL || isLH) {
  const Root = () => (
    <Provider store={store}>
      <LoginComponent skip={true}>
        {/* @ts-ignore */}
        <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
          <AppContainer />
        </DndProvider>
      </LoginComponent>
    </Provider>
  )

  const Startup = () => (
    <Suspense fallback={<MiniLoader />}>
      <Root />
    </Suspense>
  )

  const skipChecking = srv.indexOf("pcm.smart-mobility.info") < 0 && !pcmSMW.test(srv);

  const locale = localStorage.getItem("locale")
  if (typeof locale === "string") {
    document.documentElement.setAttribute("lang", locale.replace("_", "-"));
    i18n.changeLanguage(locale);
  }

  const searchParams = new URLSearchParams(window.location.search)
  const urlLocale = searchParams.get("locale")
  if (
    (urlLocale !== null && urlLocale !== locale) &&
    availableLanguages.includes(urlLocale)
  ) {
    localStorage.setItem("locale", urlLocale)
    window.location.reload()
  }

  renderIfAuthenticated(Startup, store.dispatch, skipChecking)
  //renderIfSimpleLogin(Startup, store, true);
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister()
}
