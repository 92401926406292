import React from "react"

import fancy_icons from "./icons"
import plain_icons from "@pag/center/components/icon/icons"
import { ROUTES } from "@pag/center/views/routes"
import lists from "@pag/center/views/settingsScreen/listContents/settingsListContents"
import Tile from "@pag/center/components/tile/TileContainer"
import { LauncherTile } from "@pag/center/views/launcherConfig/types"

function tile(text: string, icon: string | string[], url: string): Readonly<LauncherTile> {
  return Object.freeze({ text, icon, url })
}

function createTileComponent(tile: LauncherTile) {
  return (
    <div key={tile.url} className="tile-list__item">
      <Tile
        isSmall={false}
        text={tile.text}
        icon={tile.icon}
        url={tile.url}
      />
    </div>
  )
}

export const carTile =           tile("CenterMainScreen_Tiles_Car",           fancy_icons.car,             ROUTES.CAR)
export const mediaTile =         tile("CenterMainScreen_Tiles_Media",         fancy_icons.media,           ROUTES.MEDIA_PLAYER)
export const navigationTile =    tile("CenterMainScreen_Tiles_Navigation",    fancy_icons.navigation,      ROUTES.NAVIGATION)
export const phoneTile =         tile("CenterMainScreen_Tiles_Phone",         fancy_icons.phone,           ROUTES.PHONE)
export const carPlayTile =       tile("CenterMainScreen_Tiles_Carplay",     [ plain_icons.apple_carplay,
                                                                              fancy_icons.apple_carplay ], ROUTES.CARPLAY)
export const androidAutoTile =   tile("CenterMainScreen_Tiles_AndroidAuto", [ plain_icons.android_auto,
                                                                              fancy_icons.android_auto ],  ROUTES.ANDROID_AUTO)
export const breakDownCallTile = tile("CenterMainScreen_Tiles_BCall",         fancy_icons.breakdown_call,  ROUTES.PHONE)
export const manualTile = Object.freeze({ text:"CenterMainScreen_Tiles_eBAL", icon: fancy_icons.manual, settings_list: lists.Settings_Info })

// all available tiles in their initial order
export const tiles: Readonly<LauncherTile[]> = Object.freeze([
  carTile,
  mediaTile,
  navigationTile,
  phoneTile,
  carPlayTile,
  androidAutoTile,
  tile("CenterMainScreen_Tiles_Settings",     fancy_icons.settings,      ROUTES.SETTINGS),
  tile("CenterMainScreen_Tiles_Charge",       fancy_icons.charge,        ROUTES.CHARGING),
  tile("CenterMainScreen_Tiles_AC",           fancy_icons.climate,       ROUTES.CLIMATE),
  tile("CenterMainScreen_Tiles_Devices",      fancy_icons.devices,       ROUTES.DEVICES),
  tile("CenterMainScreen_Tiles_Service",      fancy_icons.maintenance,   ROUTES.MAINTENANCE),
  tile("CenterMainScreen_Tiles_Updates",      fancy_icons.updates,       ROUTES.UPDATES),
  tile("CenterMainScreen_Tiles_Notification", fancy_icons.notifications, ROUTES.NOTIFICATION_CENTER),
  Object.freeze({ text: "Info",         icon: fancy_icons.information, settings_list: lists.Settings_Info }),
  tile("CenterMainScreen_Tiles_Calendar",     fancy_icons.calendar,      ROUTES.CALENDAR),
  tile("CenterMainScreen_Tiles_Weather",      fancy_icons.weather,       ROUTES.WEATHER),
  tile("CenterMainScreen_Tiles_News",         fancy_icons.news,          ROUTES.NEWS),
  breakDownCallTile,
  tile("CenterMainScreen_Tiles_SportChrono",  fancy_icons.sport_chrono,  ROUTES.SPORTS_CHRONO),
  tile("CenterMainScreen_Tiles_HomeLink",     fancy_icons.homelink,      ROUTES.HOME_LINK),
  manualTile
])

export const bigTiles: Readonly<LauncherTile[]> = Object.freeze([
  carTile,
  mediaTile,
  navigationTile,
  phoneTile,
  carPlayTile,
  androidAutoTile
])

// @NOTE(kirill): it’s only used in centerPassenger
export function bigTileList(showAppleCarplay: boolean) {
  if (showAppleCarplay) {
    return bigTiles.map((tile) => createTileComponent(tile))
  }
  return bigTiles.filter((tile) => tile !== carPlayTile).map((tile) => createTileComponent(tile))
}
