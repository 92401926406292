export const ROUTES = {
  ADD_CALENDAR: "/center/add-calendar",
  ADD_FAVOURITE: "/center/add-favourite",
  ANDROID_AUTO: "/center/android-auto",
  CALENDAR: "/center/calendar",
  CAR: "/center/car",
  CARPLAY: "/center/carplay",
  CENTER_MAIN_SCREEN: "/center",
  CHARGING: "/center/charging",
  CLIMATE: "/center/climate",
  COCKPIT: "/center/cockpit",
  CONFIGURE_HUD: "/center/configure-hud",
  DEVICES: "/center/devices",
  GLOBAL_SEARCH: "/center/globalsearch",
  HOME_LINK: "/center/homelink",
  INFO: "/center/info",
  INFO_DATAPRIVACY: "/center/info-dataprivacy",
  INFO_IMPRINT2: "/center/info-imprint-2",
  INFO_IMPRINT: "/center/info-imprint-1",
  INFO_SOFTWARE: "/center/info-software",
  LAUNCHER_CONFIG: "/center/launcherEdit",
  MAINTENANCE: "/center/maintenance",
  MEDIA_PLAYER: "/center/mediaplayer",
  MEDIA_SEARCH: "/center/mediasearch",
  MY_SCREEN: "/center/myScreen",
  MY_SCREEN_CONFIG: "/center/myScreenConfig",
  MY_SCREEN_CONTEXT_OPTIONS: "/center/my-screen-context-options",
  NAVIGATION: "/center/navigation",
  NEW_ACCOUNT: "/center/new-account",
  NEWS: "/center/news",
  NOTIFICATION_CENTER: "/center/notificationCenter",
  OVERVIEW_PORSCHECONNECTSERVICES: "/center/overview-account-services",
  PARKING_ASSIST: "/center/parking-assist",
  PHONE: "/center/phone",
  SETTINGS: "/center/settings",
  SPORTS_CHRONO: "/center/sport-chrono",
  UPDATES: "/center/updates",
  WEATHER: "/center/weather",
  WEATHER_BERLIN: "/center/weather-berlin",
  WEATHER_RAIN: "/center/weather-rain",
  WEATHER_SEARCH: "/center/weather-search",
  WEATHER_STUTTGART: "/center/weather-stuttgart",
  ACCOUNTS: "/center/accounts"
}

export const ROUTES_ANIMATIONS = {
  PAGE_TRANSITION_FADE: "page-transition-fade",
  PAGE_TRANSITION_SLIDE_LEFT: "page-transition-slide-left",
  PAGE_TRANSITION_SLIDE_RIGHT: "page-transition-slide-right"
}
