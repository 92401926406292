import * as React from "react"

import settingDefs from "@pag/center/views/settingsScreen/definitions"
import { ROUTES as ROUTES_MAIN } from "@pag/center/views/routes"
import Button from "@pag/center/components/button/Button"
import I18nLabel from "@pag/center/components/I18nLabel/I18nLabel"
import InfoMessage from "@pag/center/components/infoMessage/InfoMessage"
import ScrollPanel from "@pag/center/components/scrollPanel/ScrollPanel"
import icon from "@pag/center/components/icon/icons"
import { IconType } from "@pag/center/components/icon/types"
import TyrePressureDetailScreen from "@pag/center/views/carScreen/settings/TyrePressureDetailScreen"

import "./update.scss"

export enum EEntryActions {
  NONE = "none",
  CHECKBOX = "checkbox",
  RADIOBUTTON = "radioButton",
  RADIOBUTTONNEW = "radioButtonNew",
  SLIDER = "slider",
  LINK = "link",
  RADIOBUTTON_SLIDEOUT = "radiobutton_slideout",
  SLIDEOUT = "slideout",
  EDITABLE_CHECKBOX = "editable_checkbox",
  TIME_PICKER_SLIDEOUT = "time_picker_slideout",
  DATE_PICKER_SLIDEOUT = "date_picker_slideout",
  MOVE = "move",
  POPUP = "popup",
  SWITCH = "switch",
  MORE_BUTTON = "more_button"
}

type List = {
  name: string
  entries: Entry[]
  icon?: IconType

  decoratorClass?: string | string[]
  detailScreenVideo?: string
  renderDetails?: (cb?: any) => JSX.Element
}

type Entry = {
  label: string
  linkto?: string
  action?: EEntryActions
  disabled?: boolean // NOTE(kirill): should come from state
  showNumberOfCheckedEntries?: boolean
  secondLine?: string
  secondLineInterpolationKey?: string
  end?: string
  link?: string
  icon?: IconType
  decorator?: string
  arrow?: boolean
  popupText?: string
  hideIcon?: boolean
  startIcon?: string
  divider?: true
  name?: string
  valueProps?: any
  value?: any
}

// MAIN SETTINGS ///////////////////////////////////////////////////////////////

const settings_MainList: List = {
  name: "Settings_Main",
  icon: "icon-setting",
  entries: [
    { label: "Settings_Main_Displays", linkto: "settings_DisplaysList" },
    { label: "Settings_Main_VoiceControl", linkto: "settings_VoiceControlList" },
    { label: "Settings_Main_Audio", linkto: "settings_AudioList" },
    { label: "Settings_Main_Radio", linkto: "settings_RadioList" },
    { label: "Settings_Main_Navigation", linkto: "settings_NavigationList_Settings" },
    { label: "Settings_Main_NotificationCenter", linkto: "settings_NotificationCenterList" },
    { label: "Settings_Main_Phone", linkto: "settings_PhoneList" },
    { label: "Settings_Main_Account", action: EEntryActions.LINK, link: ROUTES_MAIN.ACCOUNTS },
    { label: "Settings_Main_PrivacyAndPorscheConnect", linkto: "settings_PrivacyAndPorscheConnectList" },
    { label: "Settings_Main_Vehicle", linkto: "settings_VehicleList_Settings" },
    { label: "Settings_Main_AssistanceSystems", linkto: "settings_AssistanceSystemsList" },
    { label: "Settings_Main_SportChrono", linkto: "Settings_Main_SportChronoList" },
    { label: "Settings_Main_System", linkto: "settings_SystemList" },
    { label: "Settings_Main_SetUpAssistant", action: EEntryActions.LINK }
  ]
}

const settings_MainList_Passenger: List = {
  name: "Settings_Main",
  entries: [
    { label: "Settings_Display_PassengerDisplay", linkto: "Settings_Display_PassengerDisplayList" },
    { label: "Settings_Main_Phone", linkto: "settings_PhoneList" },
    { label: "Settings_Main_DeviceManager", linkto: "settings_DeviceManagerList" },
    { label: "Settings_Main_Sound", linkto: "settings_SoundList" }
  ]
}

// SYSTEM SETTINGS /////////////////////////////////////////////////////////////

const settings_SystemList: List = {
  name: "Settings_System",
  entries: [
    { label: "Settings_System_LanguageAndKeyboard", linkto: "Settings_System_LanguageAndKeyboardList" },
    { label: "Settings_System_DateAndTime", linkto: "Settings_System_DateAndTimeList" },
    { label: "Settings_System_Units", linkto: "Settings_System_UnitsList" },
    { label: "Settings_SoftwareInfo", linkto: "settings_SoftwareInformationList" },
    { label: "Settings_System_ResetToFactory", action: EEntryActions.NONE }
  ]
}

const Settings_System_LanguageAndKeyboardList: List = {
  name: "Settings_System_LanguageAndKeyboard",
  entries: [
    { label: "Settings_System_LanguageAndKeyboard_Language", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_System_LanguageAndKeyboard_Keyboard", action: EEntryActions.SLIDEOUT }
  ]
}

const Settings_System_DateAndTimeList: List = {
  name: "Settings_System_DateAndTime",
  icon: icon.setting,
  entries: [
    { label: "Settings_System_DateAndTime_AutomaticallySetDateAndTime", action: EEntryActions.CHECKBOX },
    { label: "Settings_System_DateAndTime_SetTimeManually", action: EEntryActions.TIME_PICKER_SLIDEOUT },
    { label: "Settings_System_DateAndTime_SetDateManually", action: EEntryActions.DATE_PICKER_SLIDEOUT },
    { label: "Settings_System_DateAndTime_SetTimeZoneAutomatically", action: EEntryActions.CHECKBOX },
    {
      label: "Settings_System_DateAndTime_SetTimeZoneManually",
      action: EEntryActions.RADIOBUTTON_SLIDEOUT,
      disabled: true
    },
    { label: "Settings_System_DateAndTime_SetTimeFormat", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_System_DateAndTime_SetDateFormat", action: EEntryActions.RADIOBUTTON_SLIDEOUT }
  ]
}

const Settings_System_TimeAndTemperatureList: List = {
  name: "Settings_System_TimeAndTemperature",
  icon: icon.setting,
  entries: [
    {
      label: "Settings_System_TimeAndTemperature_Temperature",
      action: EEntryActions.RADIOBUTTONNEW,
      value: settingDefs.timeTemp[1]
    },
    {
      label: "Settings_System_TimeAndTemperature_Time",
      action: EEntryActions.RADIOBUTTONNEW,
      value: settingDefs.timeTemp[0]
    },
    {
      label: "Settings_System_TimeAndTemperature_TimeTempSettings",
      linkto: "Settings_System_DateAndTimeList"
    },
  ]
}

const Settings_System_UnitsList: List = {
  name: "Settings_System_Units",
  entries: [
    { label: "Settings_System_Units_SpeedAndDistance", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_System_Units_Temperature", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_System_Units_Pressure", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_System_Units_Consumption", action: EEntryActions.RADIOBUTTON_SLIDEOUT }
  ]
}

// DISPLAY SETTINGS ////////////////////////////////////////////////////////////

const settings_DisplaysList: List = {
  name: "Settings_Display",
  decoratorClass: "content__displaysettings--lhd-chrono",
  entries: [
    { label: "Settings_Display_CentralDisplay", linkto: "Settings_Display_CentralDisplayList" },
    { label: "Settings_Display_CenterConsoleOperatingPanel", linkto: "Settings_Display_CenterConsoleOperatingPanelList" },
    { label: "Settings_Display_InstrumentCluster", linkto: "Settings_Display_InstrumentClusterList" },
    { label: "Settings_Display_HUD", linkto: "Settings_Display_HUDList" },
    { label: "Settings_Display_PassengerDisplay", linkto: "Settings_Display_PassengerDisplayList" },
    { label: "Settings_Display_AdditionalInstrument", linkto: "Settings_Display_AdditionalInstrumentList" }
  ]
}

const Settings_Display_CentralDisplayList: List = {
  name: "Settings_Display_CentralDisplay",
  decoratorClass: "content__centerdisplay--lhd-chrono",
  entries: [
    {
      label: "Settings_Display_CentralDisplay_Brightness",
      action: EEntryActions.SLIDER,
      valueProps: settingDefs.brightness
    },
    // { label: "Settings_Display_CentralDisplay_ProximitySensors", action: EEntryActions.CHECKBOX, selected: false },
    { label: "Settings_Display_CentralDisplay_TouchTones", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_Display_CentralDisplay_TurnOffCenterDisplay", action: EEntryActions.NONE }
  ]
}

const Settings_Display_HUDList: List = {
  name: "Settings_Display_HUD",
  decoratorClass: "content__HUD--lhd-chrono",
  entries: [
    { label: "Settings_Display_HUD_TurnOnDisplay", action: EEntryActions.CHECKBOX },
    { label: "Settings_Display_HUD_CustomisedView", action: EEntryActions.NONE },
    { label: "Settings_Display_HUD_View", action: EEntryActions.SLIDEOUT, showNumberOfCheckedEntries: true },
    { label: "Settings_Display_HUD_IncomingCalls", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_Display_HUD_TrafficSign", linkto: "Settings_Display_HUD_TrafficSignList" },
    { label: "Settings_Display_HUD_DayAndNightView", action: EEntryActions.RADIOBUTTON_SLIDEOUT }
    // { label: "Settings_Display_HUD_LicenseInfo", action: EEntryActions.CHECKBOX }
  ]
}

const Settings_Display_HUDList_Layout: List = {
  name: "Settings_Display_HUD_Custom_Adjust",
  decoratorClass: [
    "content__HUD--custom_double-status",
    "content__HUD--custom_single-status",
    "content__HUD--custom_double",
    "content__HUD--custom_single"
  ],
  entries: [
    {
      label: "Settings_Display_HUD_Custom_Adjust_MainArea",
      action: EEntryActions.RADIOBUTTON_SLIDEOUT
    },
    {
      label: "Settings_Display_HUD_Custom_Adjust_StatusBar",
      action: EEntryActions.CHECKBOX,
      disabled: true
    }
  ]
}

const Settings_Display_HUD_TrafficSignList: List = {
  name: "Settings_Display_HUD_TrafficSign",
  entries: [
    { label: "Settings_Display_HUD_TrafficSign_ShowInHUD", action: EEntryActions.CHECKBOX },
    { label: "Settings_Display_HUD_TrafficSign_SpeedingWarning", action: EEntryActions.CHECKBOX },
    {
      label: "Settings_Display_HUD_TrafficSign_Deviation",
      secondLine: "Settings_Display_HUD_TrafficSign_DeviationLine2",
      action: EEntryActions.SLIDER,
      valueProps: settingDefs.speedDeviation
    }
  ]
}

const Settings_Display_CenterConsoleOperatingPanelList: List = {
  name: "Settings_Display_CenterConsoleOperatingPanel",
  decoratorClass: "content__tbf--lhd-chrono",
  entries: [
    {
      label: "Settings_Display_CenterConsoleOperatingPanel_Brightness",
      action: EEntryActions.SLIDER,
      valueProps: settingDefs.brightness
    },
    { label: "Settings_Display_CenterConsoleOperatingPanel_TouchTones", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    {
      label: "Settings_Display_CenterConsoleOperatingPanel_HapticFeedback",
      action: EEntryActions.RADIOBUTTON_SLIDEOUT
    },
    {
      label: "Settings_Display_CenterConsoleOperatingPanel_FocusMovementSpeed",
      action: EEntryActions.RADIOBUTTON_SLIDEOUT
    }
    // { label: "Settings_Display_CenterConsoleOperatingPanel_QuickLinks", action: EEntryActions.CHECKBOX }
  ]
}

const Settings_Display_AdditionalInstrumentList: List = {
  name: "Settings_Display_AdditionalInstrument",
  decoratorClass: "content__dashboard-watch--rhd-chrono",
  entries: [
    {
      label: "Settings_Display_AdditionalInstrument_Brightness",
      action: EEntryActions.SLIDER,
      valueProps: settingDefs.brightness
    },
    { label: "Settings_Display_AdditionalInstrument_Clock", action: EEntryActions.CHECKBOX },
    { label: "Settings_Display_AdditionalInstrument_Illumination", action: EEntryActions.CHECKBOX },
    { label: "Settings_Display_AdditionalInstrument_Compass", action: EEntryActions.CHECKBOX }
  ]
}

const Settings_Display_InstrumentClusterList: List = {
  name: "Settings_Display_InstrumentCluster",
  decoratorClass: "content__ic--lhd-chrono",
  entries: [
    {
      label: "Settings_Display_InstrumentCluster_ConfigureDisplayScope",
      action: EEntryActions.SLIDEOUT,
      showNumberOfCheckedEntries: true
    },
    {
      label: "Settings_Display_InstrumentCluster_CustomisedView",
      linkto: "Settings_Display_InstrumentCluster_CustomisedViewList"
    },
    {
      label: "Settings_Display_InstrumentCluster_Brightness",
      action: EEntryActions.SLIDER,
      valueProps: settingDefs.brightness
    },
    {
      label: "Settings_Display_InstrumentCluster_PersonalSpeedLimits",
      linkto: "Settings_Display_InstrumentCluster_PersonalSpeedLimitsList"
    },
    {
      label: "Settings_Display_InstrumentCluster_TrafficSignRecognition",
      linkto: "Settings_Display_InstrumentCluster_TrafficSignRecognitionList"
    },
    {
      label: "Settings_Display_InstrumentCluster_HazardousSituations",
      action: EEntryActions.CHECKBOX
    }
    // { label: "Settings_Display_InstrumentCluster_ShowIncomingCalls", action: EEntryActions.CHECKBOX, selected: false }
  ]
}

const Settings_Display_InstrumentCluster_CustomisedViewList: List = {
  name: "Settings_Display_InstrumentCluster_CustomisedView",
  entries: [
    { label: "Settings_Display_InstrumentCluster_CustomisedView_Line1", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_Display_InstrumentCluster_CustomisedView_Line2", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_Display_InstrumentCluster_CustomisedView_Line3", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_Display_InstrumentCluster_CustomisedView_Line4", action: EEntryActions.RADIOBUTTON_SLIDEOUT }
  ]
}

const Settings_Display_InstrumentCluster_PersonalSpeedLimitsList: List = {
  name: "Settings_Display_InstrumentCluster_PersonalSpeedLimits",
  entries: [
    {
      label: "Settings_Display_InstrumentCluster_PersonalSpeedLimits_SpeedLimit1",
      secondLine: "Settings_Display_InstrumentCluster_PersonalSpeedLimits_SpeedLimit_SecondLine",
      secondLineInterpolationKey: "speed",
      action: EEntryActions.EDITABLE_CHECKBOX,
      valueProps: settingDefs.speedLimit
    },
    {
      label: "Settings_Display_InstrumentCluster_PersonalSpeedLimits_SpeedLimit2",
      secondLine: "Settings_Display_InstrumentCluster_PersonalSpeedLimits_SpeedLimit_SecondLine",
      secondLineInterpolationKey: "speed",
      action: EEntryActions.EDITABLE_CHECKBOX,
      valueProps: settingDefs.speedLimit
    }
  ]
}

const Settings_Display_InstrumentCluster_TrafficSignRecognitionList: List = {
  name: "Settings_Display_InstrumentCluster_TrafficSignRecognition",
  decoratorClass: "",
  entries: [
    {
      label: "Settings_Display_InstrumentCluster_TrafficSignRecognition_Warn",
      action: EEntryActions.CHECKBOX
    },
    {
      label: "Settings_Display_InstrumentCluster_TrafficSignRecognition_Deviation",
      secondLine: "Settings_Display_InstrumentCluster_TrafficSignRecognition_DeviationLine2",
      action: EEntryActions.SLIDER,
      valueProps: settingDefs.speedDeviation
    }
  ]
}

const Settings_Display_PassengerDisplayList: List = {
  name: "Settings_Display_PassengerDisplay",
  decoratorClass: "content__cdd--lhd-chrono",
  entries: [
    {
      label: "Settings_Display_CenterConsoleOperatingPanel_Brightness",
      action: EEntryActions.SLIDER,
      valueProps: settingDefs.brightness
    },
    // { label: "Settings_Display_CentralDisplay_ProximitySensors", action: EEntryActions.CHECKBOX, selected: false },
    // { label: "Settings_Display_PassengerDisplay_DisplayInStandbyMode", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_Display_PassengerDisplay_StandbyMode", action: EEntryActions.NONE },
    { label: "Settings_Display_PassengerDisplay_TurnOnDisplay", action: EEntryActions.CHECKBOX }
  ]
}

// PHONE SETTINGS //////////////////////////////////////////////////////////////

const settings_PhoneList: List = {
  name: "Settings_Phone_PhoneBookSettings",
  icon: "icon-phone_receiver",
  entries: [
    { label: "Settings_Main_ConnectionAssist_Headline", linkto: "Settings_Devices_ConnectionAssist" },
    { label: "Settings_Phone_GeneralSettings", linkto: "Settings_Phone_GeneralSettingsList" },
    { label: "Settings_DeviceManager_BluetoothSettings", linkto: "Settings_DeviceManager_BluetoothSettingsList" },
    { label: "Settings_DeviceManager_WiFiSettings", linkto: "Settings_DeviceManager_WiFiSettingsList" },
    { label: "Settings_DeviceManager_DataConnectionsSettings", action: EEntryActions.NONE },
    { label: "Settings_Phone_General_PreferredPhone", action: EEntryActions.NONE },
    { label: "Settings_Phone_General_SerialNumber", action: EEntryActions.NONE }
  ]
}

const Settings_Phone_GeneralSettingsList: List = {
  name: "Settings_Phone_General",
  icon: "icon-phone_receiver",
  entries: [
    { label: "Settings_Phone_General_MailboxNumber", action: EEntryActions.NONE },
    { label: "Settings_Phone_PhoneBookSettings_ContactMemory", end: "21/1000", action: EEntryActions.NONE },
    { label: "Settings_Phone_PhoneBookSettings_Criteria", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    {
      label: "Settings_Phone_PhoneBookSettings_UpdateContacts",
      secondLine: "Settings_Phone_General_UpdateContacts_SecondLine",
      action: EEntryActions.LINK
    },
    { label: "Settings_Volume_MuteNavigationDuringCalls", action: EEntryActions.CHECKBOX }
    // {
    //   label: "Settings_Phone_GeneralSettings_Checkbox_WirelessChargingLabel",
    //   action: EEntryActions.CHECKBOX,
    //   selected: false
    // }
  ]
}

const Settings_Phone_PhoneBookSettingsList: List = {
  name: "Settings_Phone_PhoneBookSettings",
  icon: "icon-phone_receiver",
  entries: [
    { label: "Settings_Phone_PhoneBookSettings_ContactMemory", end: "21/1000", action: EEntryActions.NONE },
    { label: "Settings_Phone_PhoneBookSettings_Criteria", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    {
      label: "Settings_Phone_PhoneBookSettings_UpdateContacts",
      secondLine: "Settings_Phone_General_UpdateContacts_SecondLine",
      action: EEntryActions.LINK
    }
  ]
}

// DEVICE MANAGER SETTINGS /////////////////////////////////////////////////////

const settings_DeviceManagerList: List = {
  name: "Settings_DeviceManager",
  entries: [
    { label: "Settings_DeviceManager_TelephoneSettings", linkto: "Settings_Phone_GeneralSettingsList" },
    { label: "Settings_DeviceManager_BluetoothSettings", linkto: "Settings_DeviceManager_BluetoothSettingsList" }
    // { label: "Settings_DeviceManager_WiFiSettings", linkto: "Settings_DeviceManager_WiFiSettingsList" },
    // {
    //   label: "Settings_DeviceManager_DataConnectionsSettings",
    //   linkto: "Settings_DeviceManager_DataConnectionsSettingsList"
    // }
  ]
}

const Settings_DeviceManager_BluetoothSettingsList: List = {
  name: "Settings_DeviceManager_BluetoothSettings",
  entries: [
    { label: "Settings_DeviceManager_BluetoothSettings_BT", action: EEntryActions.CHECKBOX },
    { label: "Settings_DeviceManager_BluetoothSettings_BTName", action: EEntryActions.NONE }
  ]
}

const Settings_DeviceManager_WiFiSettingsList: List = {
  name: "Settings_DeviceManager_WiFiSettings",
  entries: [
    { label: "Settings_DeviceManager_WiFiSettings_WiFi", action: EEntryActions.CHECKBOX },
    { label: "Settings_DeviceManager_WiFiSettings_EnableInternetAccess", action: EEntryActions.CHECKBOX },
    {
      label: "Settings_DeviceManager_WiFiSettings_ConfigureVehicleHotspot",
      action: EEntryActions.NONE
    }
  ]
}

const Settings_DeviceManager_WiFiSettings_ConfigureVehicleHotspotList: List = {
  name: "Settings_DeviceManager_WiFiSettings_ConfigureVehicleHotspot",
  entries: []
}

// const Settings_DeviceManager_DataConnectionsSettingsList: List = {
//   name: "Settings_DeviceManager_DataConnectionsSettings",
//   entries: [{}]
// }

// AUDIO SETTINGS //////////////////////////////////////////////////////////////

const settings_AudioList: List = {
  name: "Settings_Audio",
  entries: [
    { label: "Settings_Main_Volume", linkto: "settings_VolumeList" },
    { label: "Settings_Main_Sound", linkto: "settings_SoundList" }
  ]
}

const settings_VolumeList: List = {
  name: "Settings_Volume",
  entries: [
    { label: "Settings_Volume_NavAnnouncements", action: EEntryActions.SLIDER, valueProps: settingDefs.volume },
    { label: "Settings_Volume_AdjustToRoadNoise", action: EEntryActions.SLIDER, valueProps: settingDefs.volume },
    { label: "Settings_Volume_MuteNavigationDuringCalls", action: EEntryActions.CHECKBOX },
    { label: "Settings_Volume_Message", action: EEntryActions.CHECKBOX },
    { label: "Settings_Volume_TouchTones", action: EEntryActions.RADIOBUTTON_SLIDEOUT }
    // { label: "Settings_Volume_Ringtone", action: EEntryActions.SLIDER },
    // { label: "Settings_Volume_ParkAssistVolume", action: EEntryActions.SLIDER },
    // { label: "Settings_Volume_AdjustWhenParkAssistActive", action: EEntryActions.SLIDER }
    // { label: "Settings_Volume_Warning", action: EEntryActions.SLIDER },
    // { label: "Settings_Volume_LaneDeparture", action: EEntryActions.SLIDER },
    // { label: "Settings_Volume_BrandTone", action: EEntryActions.CHECKBOX, selected: false },
    // { label: "Settings_Volume_TouchMiko", action: EEntryActions.RADIOBUTTON_SLIDEOUT }
  ]
}

const settings_SoundList: List = {
  name: "Settings_Sound",
  entries: [
    { label: "Settings_Volume_BassAndTreble", action: EEntryActions.SLIDEOUT },
    { label: "Settings_Volume_BalanceAndFader", action: EEntryActions.SLIDEOUT }
  ]
}

// VOICE CONTROL SETTINGS //////////////////////////////////////////////////////

const settings_VoiceControlList: List = {
  name: "Settings_VoiceControl",
  entries: [
    { label: "Settings_VoiceControl_HeyPorsche", action: EEntryActions.CHECKBOX },
    {
      label: "Settings_VoiceControl_ShortDialogs1",
      secondLine: "Settings_VoiceControl_ShortDialogs2",
      action: EEntryActions.CHECKBOX
    },
    {
      label: "Settings_VoiceControl_InterruptVoiceControl1",
      secondLine: "Settings_VoiceControl_InterruptVoiceControl2",
      action: EEntryActions.CHECKBOX
    },
    { label: "Settings_VoiceControl_OnlineVoiceRecognition", action: EEntryActions.CHECKBOX }
  ]
}

// CAR-DRIVE SETTINGS //////////////////////////////////////////////////////////

const settings_CarDrive_Settings: List = {
  name: "Settings_Options",
  decoratorClass: "content__car-drive--rhd-nochrono",
  entries: [
    {
      label: "Settings_Car_Drive_IndividualDriveModeConfiguration",
      linkto: "Settings_Car_Drive_IndividualDriveModeConfiguration"
    },
    { label: "Settings_Car_Drive_RangeDriveModeConfiguration", linkto: "settings_RangeDriveMode_Configuration" },
    { label: "Settings_Car_VehicleSettings", linkto: "settings_VehicleList_Settings" }
  ]
}

const settings_RangeDriveMode_Configuration: List = {
  name: "Settings_Car_Drive_RangeDriveModeConfiguration",
  decoratorClass: "content__car-drive--rhd-nochrono",
  entries: [
    {
      label: "Settings_RangeDriveMode_Configuration_Air_Conditioning",
      disabled: true,
      linkto: "Settings_Car_Drive_RangeDriveModeConfiguration"
    },
    {
      label: "Settings_RangeDriveMode_Configuration_Maximum_Speed",
      disabled: true,
      linkto: "Settings_Car_Drive_RangeDriveModeConfiguration"
    },
    {
      label: "Settings_RangeDriveMode_Configuration_PredictiveConsumption_Title",
      secondLine: "Settings_RangeDriveMode_Configuration_PredictiveConsumption_Description",
      action: EEntryActions.CHECKBOX,
      disabled: true
    }
  ]
}

const Settings_Car_Drive_IndividualDriveModeConfiguration: List = {
  name: "Settings_Car_Drive_IndividualDriveModeConfiguration",
  decoratorClass: "content__car-drive--rhd-nochrono",
  entries: []
}

// CAR-ASSIST SETTINGS /////////////////////////////////////////////////////////

const settings_CarAssist_Settings: List = {
  name: "Settings_Options",
  decoratorClass: "content__car-assist--rhd-nochrono",
  entries: [
    { label: "Settings_Car_Assist_AssistanceSystemSettings", linkto: "settings_AssistanceSystemsList" },
    { label: "Settings_Car_Assist_CarSettings", linkto: "settings_VehicleList_Settings" }
  ]
}

// CAR-TRIP SETTINGS ///////////////////////////////////////////////////////////

const settings_CarTrip_Settings: List = {
  name: "Settings_Options",
  decoratorClass: "content__car-drive--rhd-nochrono",
  entries: [
    { label: "Settings_Car_Trip_ConfigureTripPersonally", linkto: "settings_CarTrip_ConfigureTripPersonally" },
    { label: "Settings_Car_Trip_ResetTripData", linkto: "settings_CarTrip_ResetTripData" },
    { label: "Settings_Car_Trip_VehicleSettings", linkto: "settings_VehicleList_Settings" }
  ]
}

const settings_CarTrip_ConfigureTripPersonally: List = {
  name: "Settings_Car_Trip_ConfigureTripPersonally",
  decoratorClass: "content__car-drive--rhd-nochrono",
  entries: [
    { label: "Settings_Car_Trip_ConfigureTripPersonally_line1", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_Car_Trip_ConfigureTripPersonally_line2", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_Car_Trip_ConfigureTripPersonally_line3", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_Car_Trip_ConfigureTripPersonally_line4", action: EEntryActions.RADIOBUTTON_SLIDEOUT }
  ]
}

const settings_CarTrip_ResetTripData: List = {
  name: "Settings_Car_Trip_ResetTripData",
  decoratorClass: "content__car-drive--rhd-nochrono",
  entries: [
    { label: "Settings_Car_Trip_ResetTripData_ResetTripSince", linkto: "asdf" },
    { label: "Settings_Car_Trip_ResetTripData_ResetTotalTripValues", linkto: "asdf" },
    { label: "Settings_Car_Trip_ResetTripData_ResetMaxTripValues", linkto: "asdf" },
    { label: "Settings_Car_Trip_ResetTripData_ResetAllTripData", linkto: "adsf" }
  ]
}

// CAR-COMFORT SETTINGS ////////////////////////////////////////////////////////

const settings_CarComfort_Settings: List = {
  name: "Settings_Options",
  decoratorClass: "content__car-drive--rhd-nochrono",
  entries: [{ label: "Settings_Car_Trip_VehicleSettings", linkto: "settings_VehicleList_Settings" }]
}

// VEHICLE SETTINGS ////////////////////////////////////////////////////////////

const settings_VehicleList: List = {
  name: "Settings_Options",
  detailScreenVideo: require("@pag/center/assets/mp4/car-drive-menu/vehicle.mp4"),
  entries: [
    { label: "Settings_Vehicle_ManualCleaningPositionSpoiler", action: EEntryActions.SWITCH },
    { label: "Settings_Vehicle_ParkingBrake", action: EEntryActions.SWITCH },
    { label: "Settings_Vehicle_ShortcutButtons", linkto: "Settings_Vehicle_ShortcutButtonsList" },
    { label: "Settings_Vehicle_TrunkLidAndTailgates", linkto: "Settings_Vehicle_TrunkLidAndTailgatesList" },
    { label: "Settings_Vehicle_VehicleLockingSystems", linkto: "Settings_Vehicle_VehicleLockingSystemsList" },
    { label: "Settings_Vehicle_TyrePressureMonitoring", linkto: "Settings_Vehicle_TyrePressureMonitoringList" },
    { label: "Settings_Vehicle_LightAndVisibility", linkto: "Settings_Vehicle_LightAndVisibilityList" },
    { label: "Settings_Vehicle_AdditionalChassisSettings", linkto: "Settings_Vehicle_AdditionalChassisSettingsList" }
    // { label: "Settings_Vehicle_ParkingBrake_PredictiveChassis", action: EEntryActions.NONE }
  ]
}

const settings_VehicleList_Settings: List = {
  ...settings_VehicleList,
  name: "Settings_Vehicle"
}

const Settings_Vehicle_ShortcutButtonsList: List = {
  name: "Settings_Vehicle_ShortcutButtons",
  decoratorClass: "content__jokerkey-main--lhd-nochrono",
  entries: [
    { label: "Settings_Vehicle_ShortcutButtons_SteeringWheel", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_Vehicle_ShortcutButtons_ClusterButton", action: EEntryActions.RADIOBUTTON_SLIDEOUT }
  ]
}

const Settings_Vehicle_TrunkLidAndTailgatesList: List = {
  name: "Settings_Vehicle_TrunkLidAndTailgates",
  decoratorClass: "content__flaps--mechanical",
  entries: [
    {
      label: "Settings_Vehicle_TrunkLidAndTailgates_OpenBonnet",
      action: EEntryActions.NONE,
      icon: icon.car_limousine_hood,
      decorator: "content__front-hood--open"
    },
    {
      label: "Settings_Vehicle_TrunkLidAndTailgates_OpenBoot",
      action: EEntryActions.NONE,
      icon: icon.car_sport_trunk_open_arrow,
      decorator: "content__boot--open"
    },
    {
      label: "Settings_Vehicle_TrunkLidAndTailgate_CloseBoot",
      action: EEntryActions.NONE,
      icon: icon.car_sport_trunk_close_arrow
    }
  ]
}

const Settings_Vehicle_VehicleLockingSystemsList: List = {
  name: "Settings_Vehicle_VehicleLockingSystems",
  detailScreenVideo: require("@pag/center/assets/mp4/car-drive-menu/vehicle-lock.mp4"),
  entries: [
    {
      label: "Settings_Vehicle_VehicleLockingSystems_DoorUnlocking",
      action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    {
      label: "Settings_Vehicle_VehicleLockingSystems_ComfortAccess",
      secondLine: "Settings_Vehicle_VehicleLockingSystems_ComfortAccessLine2",
      action: EEntryActions.CHECKBOX
    },
    { label: "Settings_Vehicle_VehicleLockingSystems_FoldInMirrors", action: EEntryActions.CHECKBOX },
    { label: "Settings_Vehicle_VehicleLockingSystems_LockWhileDriving", action: EEntryActions.CHECKBOX },
    {
      label: "Settings_Vehicle_VehicleLockingSystems_AutoUnlock",
      secondLine: "Settings_Vehicle_VehicleLockingSystems_AutoUnlockLine2",
      action: EEntryActions.CHECKBOX
    },
    {
      label: "Settings_Vehicle_VehicleLockingSystems_UnlockVicinity",
      secondLine: "Settings_Vehicle_VehicleLockingSystems_UnlockVicinityLine2",
      action: EEntryActions.CHECKBOX
    },
    {
      label: "Settings_Vehicle_VehicleLockingSystems_TailgateComfortAccess",
      secondLine: "Settings_Vehicle_VehicleLockingSystems_TailgateComfortAccessLine2",
      action: EEntryActions.CHECKBOX
    },
    {
      label: "Settings_Vehicle_VehicleLockingSystems_InteriorSurveillance",
      secondLine: "Settings_Vehicle_VehicleLockingSystems_InteriorSurveillanceLine2",
      action: EEntryActions.CHECKBOX
    },
    {
      label: "Settings_Vehicle_VehicleLockingSystems_AntiTheft",
      secondLine: "Settings_Vehicle_VehicleLockingSystems_AntiTheftLine2",
      action: EEntryActions.CHECKBOX
    }
  ]
}

const Settings_Vehicle_TyrePressureMonitoringList: List = {
  name: "Settings_Vehicle_TyrePressureMonitoring",
  renderDetails: () => <TyrePressureDetailScreen/>,
  entries: [
    { label: "Settings_Vehicle_TyrePressureMonitoring_PressureDeviation", action: EEntryActions.NONE },
    { label: "Settings_Vehicle_TyrePressureMonitoring_Selection", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_Vehicle_TyrePressureMonitoring_FullLoad", action: EEntryActions.CHECKBOX },
    { label: "Settings_Vehicle_TyrePressureMonitoring_ComfortPressure", action: EEntryActions.CHECKBOX }
  ]
}

const Settings_Vehicle_LightAndVisibilityList: List = {
  name: "Settings_Vehicle_LightAndVisibility",
  detailScreenVideo: require("@pag/center/assets/mp4/car-drive-menu/vehicle-lights.mp4"),
  entries: [
    { label: "Settings_Vehicle_LightAndVisibility_Interior", linkto: "Settings_Vehicle_LightAndVisibility_InteriorList" },
    { label: "Settings_Vehicle_LightAndVisibility_Exterior", linkto: "Settings_Vehicle_LightAndVisibility_ExteriorList" },
    { label: "Settings_Vehicle_LightAndVisibility_Reversing", linkto: "Settings_Vehicle_LightAndVisibility_ReversingList" },
    { label: "Settings_Vehicle_LightAndVisibility_WiperWithRainSensor", action: EEntryActions.CHECKBOX }
  ]
}

const Settings_Vehicle_LightAndVisibility_InteriorList: List = {
  name: "Settings_Vehicle_LightAndVisibility_Interior",
  decoratorClass: "content__interiorlight--lhd-chrono",
  entries: [
    {
      label: "Settings_Vehicle_LightAndVisibility_Interior_AfterGlow",
      action: EEntryActions.SLIDER,
      valueProps: settingDefs.lightAfterGlow
    },
    {
      label: "Settings_Vehicle_LightAndVisibility_Interior_Brightness",
      action: EEntryActions.SLIDER,
      valueProps: settingDefs.lightBrightness
    },
    { label: "Settings_Vehicle_LightAndVisibility_Interior_InteriorLightingLock", action: EEntryActions.CHECKBOX }
  ]
}

const Settings_Vehicle_LightAndVisibility_ExteriorList: List = {
  name: "Settings_Vehicle_LightAndVisibility_Exterior",
  decoratorClass: "content__exteriorlight--lhd-chrono",
  entries: [
    {
      label: "Settings_Vehicle_LightAndVisibility_Exterior_AfterGlow",
      action: EEntryActions.SLIDER,
      valueProps: settingDefs.lightAfterGlow
    },
    { label: "Settings_Vehicle_LightAndVisibility_Exterior_DippedBeam", action: EEntryActions.CHECKBOX },
    { label: "Settings_Vehicle_LightAndVisibility_Exterior_BeamAdaption", action: EEntryActions.CHECKBOX },
    { label: "Settings_Vehicle_LightAndVisibility_Exterior_DynamicHighBeam", action: EEntryActions.CHECKBOX }
  ]
}

const Settings_Vehicle_LightAndVisibility_ReversingList: List = {
  name: "Settings_Vehicle_LightAndVisibility_Reversing",
  detailScreenVideo: require("@pag/center/assets/mp4/car-drive-menu/vehicle-lights-reversing.mp4"),
  entries: [
    { label: "Settings_Vehicle_LightAndVisibility_Reversing_WipeWhenReversing", action: EEntryActions.CHECKBOX },
    { label: "Settings_Vehicle_LightAndVisibility_Reversing_LowerMirror", action: EEntryActions.CHECKBOX }
  ]
}

const Settings_Vehicle_AdditionalChassisSettingsList: List = {
  name: "Settings_Vehicle_AdditionalChassisSettings",
  detailScreenVideo: require("@pag/center/assets/mp4/car-drive-menu/vehicle-chassis.mp4"),
  entries: [
    { label: "Settings_Vehicle_AdditionalChassisSettings_RearAxle", action: EEntryActions.CHECKBOX },
    { label: "Settings_Vehicle_AdditionalChassisSettings_ChassisAdaption", action: EEntryActions.CHECKBOX }
  ]
}

// ASSISTANCE SYSTEM SETTINGS //////////////////////////////////////////////////

const settings_AssistanceSystemsList: List = {
  name: "Settings_AssistanceSystems",
  entries: [
    { label: "Settings_AssistanceSystems_ParkAssist", linkto: "Settings_AssistanceSystems_ParkAssistList" },
    {
      label: "Settings_AssistanceSystems_WarnAndBrakeAssist",
      linkto: "Settings_AssistanceSystems_WarnAndBrakeAssistList"
    },
    { label: "Settings_AssistanceSystems_PorscheInnoDrive", linkto: "Settings_AssistanceSystems_PorscheInnoDriveList" },
    {
      label: "Settings_AssistanceSystems_AdaptiveSpeedLimiter",
      linkto: "Settings_AssistanceSystems_AdaptiveSpeedLimiterList"
    },
    {
      label: "Settings_AssistanceSystems_LaneDepartureWarning",
      linkto: "Settings_AssistanceSystems_LaneDepartureWarningList"
    },
    {
      label: "Settings_AssistanceSystems_LaneChangeAssist",
      linkto: "Settings_AssistanceSystems_LaneChangeAssistList"
    },
    {
      label: "Settings_AssistanceSystems_NightViewAssist",
      linkto: "Settings_AssistanceSystems_NightViewAssistList"
    },
    {
      label: "Settings_AssistanceSystems_TrafficSignRecognition",
      linkto: "Settings_AssistanceSystems_TrafficSignRecognitionList"
    },
    {
      label: "Settings_AssistanceSystems_PersonalSpeedLimits",
      linkto: "Settings_Display_InstrumentCluster_PersonalSpeedLimitsList"
    },
    {
      label: "Settings_AssistanceSystems_HazardousConditions",
      linkto: "Settings_AssistanceSystems_HazardousConditionsList"
    },
    { label: "Settings_AssistanceSystems_ExteriorLights", linkto: "Settings_AssistanceSystems_ExteriorLightsList" }
    // { label: "Settings_AssistanceSystems_DataExport", linkto: "Settings_AssistanceSystems_DataExportList" }
  ]
}

const Settings_AssistanceSystems_DataExportList: List = {
  name: "Settings_AssistanceSystems_DataExport",
  renderDetails: () => (
    <InfoMessage
      useFullScreenWrapper={false}
      developerText="Settings_AssistanceSystems_DataExport_Detail"
      icon={icon.information_outline}
    ></InfoMessage>
  ),
  entries: [
    {
      label: "Settings_AssistanceSystems_DataExport_Export",
      secondLine: "Settings_AssistanceSystems_DataExport_ExportSecondLine",
      action: EEntryActions.NONE
    }
  ]
}

const Settings_AssistanceSystems_ExteriorLightsList: List = {
  name: "Settings_AssistanceSystems_ExteriorLights",
  entries: [
    { label: "Settings_AssistanceSystems_ExteriorLights_BeamAdaption", action: EEntryActions.CHECKBOX },
    { label: "Settings_AssistanceSystems_ExteriorLights_DynamicHighBeam", action: EEntryActions.CHECKBOX }
  ]
}

const Settings_AssistanceSystems_TrafficSignRecognitionList: List = {
  name: "Settings_AssistanceSystems_TrafficSignRecognition",
  entries: [
    { label: "Settings_AssistanceSystems_TrafficSignRecognition_Cluster", action: EEntryActions.CHECKBOX },
    {
      label: "Settings_AssistanceSystems_TrafficSignRecognition_Exceed",
      secondLine: "Settings_AssistanceSystems_TrafficSignRecognition_Exceed_Secondline",
      action: EEntryActions.SLIDER,
      valueProps: settingDefs.speedDeviation
    }
  ]
}

const Settings_AssistanceSystems_HazardousConditionsList: List = {
  name: "Settings_AssistanceSystems_HazardousConditions",
  entries: [
    { label: "Settings_AssistanceSystems_HazardousConditions_Cluster", action: EEntryActions.CHECKBOX }
    // { label: "Settings_AssistanceSystems_HazardousConditions_HeadUp", action: EEntryActions.CHECKBOX }
  ]
}

const Settings_AssistanceSystems_NightViewAssistList: List = {
  name: "Settings_AssistanceSystems_NightViewAssist",
  entries: [
    {
      label: "Settings_AssistanceSystems_NightViewAssist_Contrast",
      action: EEntryActions.SLIDER,
      valueProps: settingDefs.contrast
    },
    { label: "Settings_AssistanceSystems_NightViewAssist_WarningTime", action: EEntryActions.RADIOBUTTON_SLIDEOUT }
  ]
}

const Settings_AssistanceSystems_AdaptiveSpeedLimiterList: List = {
  name: "Settings_AssistanceSystems_AdaptiveSpeedLimiter",
  entries: [
    { label: "Settings_AssistanceSystems_AdaptiveSpeedLimiter_ConsiderSpeedLimits", action: EEntryActions.CHECKBOX },
    {
      label: "Settings_AssistanceSystems_AdaptiveSpeedLimiter_MaximumSpeed",
      action: EEntryActions.SLIDER,
      valueProps: settingDefs.adaptiveSpeedLimit
    },
    { label: "Settings_AssistanceSystems_AdaptiveSpeedLimiter_AdoptionOfSpeedlimits", action: EEntryActions.RADIOBUTTON_SLIDEOUT }
  ]
}

const Settings_AssistanceSystems_PorscheInnoDriveList: List = {
  name: "Settings_AssistanceSystems_PorscheInnoDrive",
  entries: [
    {
      label: "Settings_AssistanceSystems_PorscheInnoDrive_ConsiderSpeedLimits",
      secondLine: "Settings_AssistanceSystems_PorscheInnoDrive_ConsiderSpeedLimits_SecondLine",
      action: EEntryActions.CHECKBOX
    },
    {
      label: "Settings_AssistanceSystems_PorscheInnoDrive_MaxSpeed",
      action: EEntryActions.SLIDER,
      valueProps: settingDefs.adaptiveSpeedLimit
    }
  ]
}

const Settings_AssistanceSystems_ParkAssistList: List = {
  name: "Settings_AssistanceSystems_ParkAssist_Headline",
  entries: [
    { label: "Settings_AssistanceSystems_ParkAssist_Volume", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    {
      label: "Settings_AssistanceSystems_ParkAssist_VolumeReduction",
      action: EEntryActions.SLIDER,
      valueProps: settingDefs.volumeReduction
    },
    { label: "Settings_AssistanceSystems_ParkAssist_AutoActivate", action: EEntryActions.CHECKBOX }
  ]
}

const Settings_AssistanceSystems_WarnAndBrakeAssistList: List = {
  name: "Settings_AssistanceSystems_WarnAndBrakeAssist",
  entries: [
    {
      label: "Settings_AssistanceSystems_WarnAndBrakeAssist_CollisionWarning",
      secondLine: "Settings_AssistanceSystems_WarnAndBrakeAssist_CollisionWarning_SecondLine",
      action: EEntryActions.RADIOBUTTON_SLIDEOUT
    },
    {
      label: "Settings_AssistanceSystems_WarnAndBrakeAssist_DistanceWarning",
      secondLine: "Settings_AssistanceSystems_WarnAndBrakeAssist_DistanceWarning_SecondLine",
      action: EEntryActions.RADIOBUTTON_SLIDEOUT
    },
    { label: "Settings_AssistanceSystems_WarnAndBrakeAssist_TurnAssist", action: EEntryActions.CHECKBOX },
    { label: "Settings_AssistanceSystems_WarnAndBrakeAssist_SwerveAssist", action: EEntryActions.CHECKBOX }
  ]
}

const Settings_AssistanceSystems_LaneDepartureWarningList: List = {
  name: "Settings_AssistanceSystems_LaneDepartureWarning",
  entries: [
    { label: "Settings_AssistanceSystems_LaneDepartureWarning_Volume", action: EEntryActions.RADIOBUTTON_SLIDEOUT }
  ]
}

const Settings_AssistanceSystems_LaneChangeAssistList: List = {
  name: "Settings_AssistanceSystems_LaneChangeAssist",
  entries: [
    { label: "Settings_AssistanceSystems_LaneChangeAssist_Brightness", action: EEntryActions.RADIOBUTTON_SLIDEOUT }
  ]
}

// NOTIFICATION CENTER SETTINGS ////////////////////////////////////////////////

const settings_NotificationCenterList: List = {
  name: "Settings_NotificationCenter",
  entries: [
    // { label: "Settings_NotificationCenter_Allow", action: EEntryActions.SLIDEOUT },
    { label: "Settings_NotificationCenter_Sound", action: EEntryActions.CHECKBOX },
    {
      icon: "icon-car_front_j1",
      label: "Settings_NotificationCenter_CarNotifications",
      linkto: "Settings_NotificationCenter_CarNotificationsList"
    },
    {
      icon: "icon-communication",
      label: "Settings_NotificationCenter_PhoneNotifications",
      linkto: "Settings_NotificationCenter_PhoneNotificationsList"
    },
    {
      icon: "icon-bubble_info",
      label: "Settings_NotificationCenter_InfoNotifications",
      linkto: "Settings_NotificationCenter_InfoNotificationsList"
    }
  ]
}

const Settings_NotificationCenter_PhoneNotificationsList: List = {
  name: "Settings_NotificationCenter_PhoneNotifications",
  entries: [
    { label: "Settings_NotificationCenter_ShowAll", action: EEntryActions.CHECKBOX },
    { label: "Settings_NotificationCenter_MissedCall", action: EEntryActions.CHECKBOX },
    { label: "Settings_NotificationCenter_Email", action: EEntryActions.CHECKBOX },
    { label: "Settings_NotificationCenter_Allow_Sms", action: EEntryActions.CHECKBOX }
  ]
}

const Settings_NotificationCenter_InfoNotificationsList: List = {
  name: "Settings_NotificationCenter_InfoNotifications",
  entries: [
    { label: "Settings_NotificationCenter_ShowAll", action: EEntryActions.CHECKBOX },
    { label: "Settings_NotificationCenter_Allow_Speech", action: EEntryActions.CHECKBOX },
    { label: "Settings_NotificationCenter_Allow_Connectivity", action: EEntryActions.CHECKBOX },
    { label: "Settings_NotificationCenter_Calendar", action: EEntryActions.CHECKBOX }
  ]
}

const Settings_NotificationCenter_CarNotificationsList: List = {
  name: "Settings_NotificationCenter_CarNotifications",
  entries: [
    { label: "Settings_NotificationCenter_ShowAll", action: EEntryActions.CHECKBOX },
    { label: "Settings_NotificationCenter_Service", action: EEntryActions.CHECKBOX },
    { label: "Settings_NotificationCenter_Allow_Update", action: EEntryActions.CHECKBOX },
    { label: "Settings_NotificationCenter_SmartService", action: EEntryActions.CHECKBOX }
  ]
}

// RADIO SETTINGS //////////////////////////////////////////////////////////////

const settings_RadioList: List = {
  name: "Settings_Radio",
  entries: [
    { label: "Settings_Radio_TrafficNotices", action: EEntryActions.CHECKBOX },
    { label: "Settings_Radio_StationTracking", action: EEntryActions.CHECKBOX },
    { label: "Settings_Radio_OnlineStationTracking", action: EEntryActions.CHECKBOX },
    { label: "Settings_Radio_HDRadio", action: EEntryActions.CHECKBOX },
    { label: "Settings_Radio_ShowProgram", action: EEntryActions.CHECKBOX }
  ]
}

// NAVIGATION SETTINGS /////////////////////////////////////////////////////////

const settings_NavigationList: List = {
  name: "Settings_Options",
  icon: "icon-north_arrow",
  entries: [
    { label: "Settings_Navigation_RouteOptions", linkto: "Settings_Navigation_RouteOptionsList" }, //
    { label: "Settings_Navigation_EditRoute", action: EEntryActions.LINK, arrow: true }, //linkto: "Settings_Navigation_EditRouteList"
    { label: "Settings_Navigation_RouteOptions_Avoid", action: EEntryActions.SLIDEOUT },
    { label: "Settings_Navigation_AlternativeRoutes", action: EEntryActions.LINK, arrow: true }, //linkto: "Settings_Navigation_AlternativeRoutesList"
    { label: "Settings_Navigation_RouteOptions_DisplayRouteSelection", action: EEntryActions.CHECKBOX },
    {
      label: "Settings_Navigation_SaveDestination",
      action: EEntryActions.POPUP,
      popupText: "Settings_Navigation_SaveDestination_PopupText"
    },
    // { label: "Settings_Navigation_TrafficInformationPopup", action: EEntryActions.CHECKBOX, selected: true },
    { label: "Settings_Navigation_NavigationSettings", linkto: "Settings_Navigation_NavigationSettingsList" }
  ]
}

const settings_NavigationList_Settings: List = {
  name: "Settings_Main_Navigation_Settings",
  icon: "icon-north_arrow",
  entries: [...settings_NavigationList.entries]
}

const Settings_Navigation_RouteOptionsList: List = {
  name: "Settings_Navigation_RouteOptions",
  icon: "icon-setting",
  entries: [
    // {
    //   label: "Settings_Navigation_RouteOptions_ShowRoutesWhenStartingRoutesGuidance",
    //   action: EEntryActions.CHECKBOX,
    //   selected: false
    // },
    { label: "Settings_Navigation_RouteOptions_PorscheChargingPlaner", action: EEntryActions.CHECKBOX },
    {
      label: "Settings_Navigation_RouteOptions_ManualBatteryChargeAtDestination",
      action: EEntryActions.SLIDER,
      valueProps: settingDefs.charge
    },
    {
      label: "Settings_Navigation_RouteOptions_PorscheChargingService",
      secondLine: "Settings_Navigation_RouteOptions_PorscheChargingService_SecondLine",
      action: EEntryActions.CHECKBOX
    },
    { label: "Settings_Navigation_NavigationSettings_RangeWarning", action: EEntryActions.CHECKBOX }
  ]
}

const Settings_Navigation_AlternativeRoutesList: List = {
  name: "Settings_Navigation_AlternativeRoutes",
  icon: "icon-north_arrow",
  entries: []
}

const Settings_Navigation_EditRouteList: List = {
  name: "Settings_Navigation_EditRoute",
  icon: "icon-north_arrow",
  entries: []
}

const Settings_Navigation_NavigationSettingsList: List = {
  name: "Settings_Navigation_NavigationSettings",
  icon: "icon-north_arrow",
  entries: [
    {
      label: "Settings_Navigation_NavigationSettings_MapSettings",
      linkto: "Settings_Navigation_NavigationSettings_MapSettingsList"
    },
    { label: "Settings_Navigation_NavigationSettings_DisplayCountryInformation", action: EEntryActions.CHECKBOX },
    {
      label: "Settings_Navigation_NavigationSettings_NavigationAnnouncements",
      action: EEntryActions.SLIDER,
      valueProps: settingDefs.navigationAnnouncements
    },
    // {
    //   label: "Settings_Navigation_NavigationSettings_TrafficInformation",
    //   linkto: "Settings_Navigation_NavigationSettings_TrafficInformationList"
    // },
    // { label: "Settings_Navigation_NavigationSettings_SmartRouting", action: EEntryActions.CHECKBOX, selected: false },
    // {
    //   label: "Settings_Navigation_NavigationSettings_SmartRoutingDeleteHistory",
    //   action: EEntryActions.POPUP,
    //   popupText: "Settings_Navigation_NavigationSettings_SmartRoutingDeleteHistory_PopupText"
    // },
    // {
    //   label: "Settings_Navigation_NavigationSettings_VoiceGuidanceMode",
    //   linkto: "Settings_Navigation_NavigationSettings_VoiceGuidanceModeList"
    // },
    { label: "Settings_Volume_MuteNavigationDuringCalls", action: EEntryActions.CHECKBOX },
    {
      label: "Settings_Navigation_NavigationSettings_DeleteRecentDestinationsAndFavorites",
      action: EEntryActions.NONE,
      disabled: true
    },
    { label: "Settings_Navigation_NavigationSettings_DemoMode", action: EEntryActions.CHECKBOX },
    {
      label: "Settings_Navigation_NavigationSettings_SetLastDestinationAsStartingPoint",
      action: EEntryActions.NONE,
      disabled: true
    }
  ]
}

const Settings_Navigation_NavigationSettings_MapSettingsList: List = {
  name: "Settings_Navigation_NavigationSettings_MapSettings",
  icon: "icon-north_arrow",
  entries: [
    { label: "Settings_Navigation_NavigationSettings_MapSettings_AutoZoom", action: EEntryActions.CHECKBOX },
    { label: "Settings_Navigation_NavigationSettings_MapSettings_DisplayPois", action: EEntryActions.CHECKBOX },
    { label: "Settings_Navigation_NavigationSettings_MapSettings_3DBuilding", action: EEntryActions.CHECKBOX },
    { label: "Settings_Navigation_NavigationSettings_MapSettings_ShowSpeedLimits", action: EEntryActions.CHECKBOX },
    { label: "Settings_Navigation_NavigationSettings_MapSettings_DayAndNightView", action: EEntryActions.RADIOBUTTON_SLIDEOUT }
  ]
}

const Settings_Navigation_NavigationSettings_TrafficInformationList: List = {
  name: "Settings_Navigation_NavigationSettings_TrafficInformation",
  icon: "icon-north_arrow",
  entries: [
    { label: "Settings_Navigation_NavigationSettings_TrafficInformation_Merging", action: EEntryActions.CHECKBOX },
    { label: "Settings_Navigation_NavigationSettings_TrafficInformation_RailroadCrossing", action: EEntryActions.CHECKBOX }
  ]
}

// const Settings_Navigation_NavigationSettings_VoiceGuidanceModeList = {
//   name: "Settings_Navigation_NavigationSettings_VoiceGuidanceMode",
//   icon: "icon-north_arrow",
//   entries: [
//     {
//       label: "Settings_Navigation_NavigationSettings_VoiceGuidanceMode_InDetail",
//       action: EEntryActions.RADIOBUTTON,
//       selected: false
//     },
//     {
//       label: "Settings_Navigation_NavigationSettings_VoiceGuidanceMode_Simple",
//       action: EEntryActions.RADIOBUTTON,
//       selected: true
//     },
//     {
//       label: "Settings_Navigation_NavigationSettings_VoiceGuidanceMode_Off",
//       action: EEntryActions.RADIOBUTTON,
//       selected: false
//     }
//   ]
// }

// MEDIA SETTINGS //////////////////////////////////////////////////////////////

const settings_MediaList: List = {
  name: "Settings_Options",
  entries: [
    { label: "Settings_Media_AdjustSortingOfSources", linkto: "Settings_Media_AdjustSortingOfSourcesList" },
    { label: "Settings_Radio", linkto: "settings_RadioList" },
    { label: "Settings_Main_DeviceManager", linkto: "settings_DeviceManagerList" },
    { label: "Settings_Main_PrivacyAndPorscheConnect", linkto: "settings_PrivacyAndPorscheConnectList" }
  ]
}

const Settings_Media_AdjustSortingOfSourcesList: List = {
  name: "Settings_Media_AdjustSortingOfSources",
  renderDetails: (cb: any) => (
    <InfoMessage
      useFullScreenWrapper={false}
      developerText="Settings_Media_AdjustSortingOfSources_Detail_InfoMessage_LabelText_DragAroundToRearrange"
      icon={icon.information_outline}
    >
      <Button
        labelText="Settings_Media_AdjustSortingOfSources_Detail_InfoMessage_ButtonText_Confirm"
        onClick={() => cb()}
      />
    </InfoMessage>
  ),
  entries: settingDefs.mediaSources.map((source) => Object.assign({ action: EEntryActions.MOVE }, source))
}

// PHONE TILE SETTINGS /////////////////////////////////////////////////////////

const settings_PhoneTileList: List = {
  name: "Settings_Options",
  icon: "icon-phone_receiver",
  entries: [
    { label: "Settings_Main_DeviceManager", linkto: "settings_DeviceManagerList" },
    { label: "Settings_PhoneContext_PhoneSettings", linkto: "settings_PhoneList", hideIcon: true }
  ]
}

const settings_PhoneTileList_Contacts: List = {
  name: "Settings_Options",
  icon: "icon-phone_receiver",
  entries: [
    { label: "Settings_Phone_PhoneBookSettings_Criteria", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_Main_DeviceManager", linkto: "settings_DeviceManagerList" },
    { label: "Settings_PhoneContext_PhoneSettings", linkto: "settings_PhoneList", hideIcon: true }
  ]
}

// NEWS TILE SETTINGS //////////////////////////////////////////////////////////

const settings_NewsTileList: List = {
  name: "Settings_Options",
  icon: "icon-news",
  entries: [
    { label: "NewsScreen_StartNewsWizard", action: EEntryActions.NONE },
    { label: "NewsScreen_Imprint", action: EEntryActions.NONE },
    { label: "NewsScreen_DataPrivacy", action: EEntryActions.NONE },
    { label: "NewsScreen_ResetNews", action: EEntryActions.NONE },
    { label: "NewsScreen_Sort_Favorites", action: EEntryActions.NONE },
    { label: "NewsScreen_VersionInformation", action: EEntryActions.NONE },
  ]
}

// CALENDAR TILE SETTINGS //////////////////////////////////////////////////////

const settings_CalendarTileList: List = {
  name: "Settings_Options",
  entries: [
    {
      label: "Calendar_Settings_Disconnect",
      action: EEntryActions.NONE
    },
    {
      label: "Calendar_Settings_PhoneCalendar",
      action: EEntryActions.NONE
    },
    {
      label: "Calendar_Settings_PorscheCalendar",
      action: EEntryActions.NONE
    },
    {
      label: "Calendar_Settings_StartWeek",
      action: EEntryActions.RADIOBUTTON_SLIDEOUT
    },
    {
      label: "Calendar_Settings_AddKalendaer",
      linkto: "Calendar_Settings_AddKalendaerList"
    },
    {
      label: "Calendar_Settings_PorscheConnect",
      linkto: "settings_PrivacyAndPorscheConnectList"
    },
    {
      label: "Calendar_Settings_Imprint",
      action: EEntryActions.NONE
    }
  ]
}

const Calendar_Settings_AddKalendaerList: List = {
  name: "Calendar_Settings_AddKalendaerList_Headline",
  entries: [
    {
      label: "Calendar_Settings_AddKalendaerList_Google",
      action: EEntryActions.NONE,
      disabled: true
    },
    {
      label: "Calendar_Settings_AddKalendaerList_Office365",
      action: EEntryActions.NONE,
      disabled: true
    },
    {
      label: "Calendar_Settings_AddKalendaerList_Exchange",
      action: EEntryActions.LINK,
      link: ROUTES_MAIN.ADD_CALENDAR
    }
  ]
}

// WEATHER TILE SETTINGS ///////////////////////////////////////////////////////

const settings_WeatherTileList: List = {
  name: "Settings_Options",
  entries: []
}

// CHARGING SETTINGS ///////////////////////////////////////////////////////////

const settings_ChargingList: List = {
  name: "Settings_Options",
  entries: [
    {
      label: "Settings_Charging_ChargingPlug-FirstLine",
      secondLine: "Settings_Charging_ChargingPlug-SecondLine",
      action: EEntryActions.NONE
    },
    {
      label: "Settings_Charging_ActivatePlugAndCharge-FirstLine",
      secondLine: "Settings_Charging_ActivatePlugAndCharge-SecondLine",
      action: EEntryActions.CHECKBOX
    },
    {
      label: "Settings_Charging_BatteryConditionCharging-FirstLine",
      secondLine: "Settings_Charging_BatteryConditionCharging-SecondLine",
      action: EEntryActions.CHECKBOX
    }
  ]
}

// DEVICES SETTINGS ////////////////////////////////////////////////////////////

const settings_DevicesList: List = {
  name: "Settings_Options",
  entries: [
    { label: "Settings_DeviceManager_TelephoneSettings", linkto: "Settings_Phone_GeneralSettingsList" },
    { label: "Settings_DeviceManager_BluetoothSettings", linkto: "Settings_DeviceManager_BluetoothSettingsList" },
    { label: "Settings_DeviceManager_WiFiSettings", linkto: "Settings_DeviceManager_WiFiSettingsList" }
  ]
}

const Settings_Devices_ConnectionAssist: List = {
  name: "Settings_Main_ConnectionAssist",
  entries: [
    {
      label: "ConnectionAssistant_NewPhone",
      action: EEntryActions.POPUP,
      startIcon: icon.cell_phone,
      popupText: "Popup_Helptext_functionality-not-available"
    },
    {
      label: "ConnectionAssistant_NewMusicPlayer",
      action: EEntryActions.POPUP,
      startIcon: icon.note,
      popupText: "Popup_Helptext_functionality-not-available"
    },
    {
      label: "ConnectionAssistant_ConnectAppleCarPlay",
      action: EEntryActions.POPUP,
      startIcon: icon.apple_carplay,
      popupText: "Popup_Helptext_functionality-not-available"
    }
  ]
}

// UPDATE SETTINGS /////////////////////////////////////////////////////////////

const settings_UpdatesList: List = {
  name: "Settings_Options",
  renderDetails: () => (
    <ScrollPanel className="update-details-container__description-area details-screen__text-container">
      <I18nLabel
        className="update-details-container__description"
        text="Settings_Updates_WebApp_Detail_InfoMessage_LabelText_Line1"
      />
      <I18nLabel
        className="update-details-container__description"
        text="Settings_Updates_WebApp_Detail_InfoMessage_LabelText_Line2"
      />
    </ScrollPanel>
  ),
  entries: []
}

// SPORT CHRONO SETTINGS ///////////////////////////////////////////////////////

const Settings_Main_SportChronoList: List = {
  name: "Settings_SportChrono",
  icon: icon.timer,
  entries: [
    { label: "Settings_SportChrono_MaxDelta", action: EEntryActions.SLIDER, valueProps: settingDefs.chronoMaxDelta },
    { label: "Settings_SportChrono_Deviation", action: EEntryActions.SLIDER, valueProps: settingDefs.chronoDeviation },
    { label: "Settings_SportChrono_ExtendedDataRecording", action: EEntryActions.CHECKBOX },
    { label: "Settings_SportChrono_NewLap", action: EEntryActions.CHECKBOX },
    { label: "Settings_SportChrono_DisplayPos", action: EEntryActions.CHECKBOX }
  ]
}

// PRIVACY AND PORSCHE CONNECT SETTINGS ////////////////////////////////////////

const settings_PrivacyAndPorscheConnectList: List = {
  name: "Settings_PrivacyAndPorscheConnect",
  entries: [
    { label: "Settings_PrivacyAndPorscheConnect_PrivateMode", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_PrivacyAndPorscheConnect_ServicesWithoutTracking", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    // { label: "Settings_PrivacyAndPorscheConnect_ServiceWithTracking", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_PrivacyAndPorscheConnect_ServicesWithTracking", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    // { label: "Settings_PrivacyAndPorscheConnect_GPS", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    // { label: "Settings_PrivacyAndPorscheConnect_DataFromPorsche", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    // { label: "Settings_PrivacyAndPorscheConnect_AnonData", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    //
    { label: "Settings_PrivacyAndPorscheConnect_Divider_DataAndAnalysis", divider: true },

    { label: "Settings_PrivacyAndPorscheConnect_Improvements", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_PrivacyAndPorscheConnect_ConsentData", action: EEntryActions.RADIOBUTTON_SLIDEOUT },

    { label: "Settings_PrivacyAndPorscheConnect_Divider_Other", divider: true },

    { label: "Settings_PrivacyAndPorscheConnect_Overview", action: EEntryActions.NONE, secondLine: "Settings_PrivacyAndPorscheConnect_Overview_SecondLine" },
    {
      label: "Settings_PrivacyAndPorscheConnect_ExternalAuthorisations",
      linkto: "Settings_PrivacyAndPorscheConnect_ExternalAuthorisationsList"
    },
    // { label: "Settings_PrivacyAndPorscheConnect_ResidualData", action: EEntryActions.NONE },
    { label: "Settings_PrivacyAndPorscheConnect_LegalNotice", action: EEntryActions.NONE },
    { label: "Settings_PrivacyAndPorscheConnect_Privacy", action: EEntryActions.NONE }
  ]
}

const Settings_PrivacyAndPorscheConnect_ExternalAuthorisationsList: List = {
  name: "Settings_PrivacyAndPorscheConnect_ExternalAuthorisations",
  entries: [
    { label: "Settings_PrivacyAndPorscheConnect_ExternalAuthorisations_NoEntries", action: EEntryActions.NONE }
  ]
}

// WEATHER SETTINGS ////////////////////////////////////////////////////////////

const Settings_WeatherList: List = {
  name: "Settings_Options",
  entries: [
    { label: "Settings_PrivacyAndPorscheConnect_LegalNotice", action: EEntryActions.NONE },
    { label: "Settings_PrivacyAndPorscheConnect_Privacy", action: EEntryActions.NONE },
    { label: "Settings_Weather_SortingFav", action: EEntryActions.NONE, disabled: true }
  ]
}

// SOFTWARE INFO SETTINGS //////////////////////////////////////////////////////

const settings_SoftwareInformationList: List = {
  name: "Settings_SoftwareInfo",
  entries: [
    { label: "Settings_SoftwareInfo_VersionInfo", linkto: "settings_SoftwareInformation_VersionInfoList" },
    { label: "Settings_SoftwareComponents", action: EEntryActions.NONE }
  ]
}

const settings_SoftwareInformation_VersionInfoList: List = {
  name: "Settings_SoftwareInfo",
  entries: [
    {
      label: "Settings_SoftwareInformation_UserInterface",
      secondLine: "release-37.17-2147-SNAPSHOT-23.11.2021-16:49:13",
      action: EEntryActions.NONE
    },
    {
      label: "Settings_SoftwareInformation_SoftwareVersion",
      secondLine: "MPR3_ER_POG46_P3776",
      action: EEntryActions.NONE
    },
    {
      label: "Settings_SoftwareInformation_SoftwareVersionPCM",
      secondLine: "3776",
      action: EEntryActions.NONE
    },
    {
      label: "Settings_SoftwareInformation_Hardware",
      secondLine: "H9J1035070BB",
      action: EEntryActions.NONE
    },
    {
      label: "Settings_SoftwareInformation_TextVersion",
      secondLine: "MIB3_GUI_37.30_20211119",
      action: EEntryActions.NONE
    },
    // {
    //   label: "Navigation Database",
    //   secondLine: "---",
    //   action: EEntryActions.NONE
    // },
    {
      label: "Settings_SoftwareInformation_NavigationPacket",
      secondLine: "V03959817FA_P0760_EU_2022.05",
      action: EEntryActions.NONE
    },
    {
      label: "Settings_SoftwareInformation_GraceNote",
      secondLine: "Region=EUROPE - Version=9",
      action: EEntryActions.NONE
    },
    // {
    //   label: "Settings_SoftwareInformation_PhoneDriver",
    //   secondLine: "---",
    //   action: EEntryActions.NONE
    // }
  ]
}

// DEVICES SETTINGS ////////////////////////////////////////////////////////////

const settings_NotificationList: List = {
  name: "Settings_Options",
  entries: [{ label: "Settings_Notification_NotificationCenter", linkto: "Settings_Notification_NotificationCenter" }]
}

const Settings_Notification_NotificationCenter: List = {
  name: "Settings_Notification_NotificationCenter_Settings",
  entries: [
    //{ label: "Settings_NotificationCenter_Allow", action: EEntryActions.SLIDEOUT },
    { label: "Settings_NotificationCenter_Sound", action: EEntryActions.CHECKBOX }
  ]
}

const Settings_Notification_NotificationCenter_ReceiveVehicleNotifcationList: List = {
  name: "Settings_Notification_NotificationCenter_ReceiveVehicleNotifcations",
  entries: [
    // {
    //   label: "Settings_Notification_NotificationCenter_ReceiveVehicleNotifcation_ShowAll",
    //   action: EEntryActions.CHECKBOX,
    //   selected: true
    // },
    {
      label: "Settings_Notification_NotificationCenter_ReceiveVehicleNotifcation_ServiceInformation",
      action: EEntryActions.CHECKBOX
    },
    {
      label: "Settings_Notification_NotificationCenter_ReceiveVehicleNotifcation_Updates",
      action: EEntryActions.CHECKBOX
    }
  ]
}

// const Settings_Notification_NotificationCenter_ReceiveSmartPhoneNotificationsList: List = {
//   name: "Settings_Notification_NotificationCenter_ReceiveSmartPhoneNotifications",
//   entries: [
//     // {
//     //   label: "Settings_Notification_NotificationCenter_ReceiveSmartPhoneNotifications_ShowAll",
//     //   action: EEntryActions.CHECKBOX,
//     //   selected: true
//     // },
//     {
//       label: "Settings_Notification_NotificationCenter_ReceiveSmartPhoneNotifications_MissedCalls",
//       action: EEntryActions.CHECKBOX,
//       selected: true
//     },
//     {
//       label: "Settings_Notification_NotificationCenter_ReceiveSmartPhoneNotifications_Emails",
//       action: EEntryActions.CHECKBOX,
//       selected: true
//     },
//     {
//       label: "Settings_Notification_NotificationCenter_ReceiveSmartPhoneNotifications_Messages",
//       action: EEntryActions.CHECKBOX,
//       selected: true
//     }
//   ]
// }

// const Settings_Notification_NotificationCenter_ReceiveInformationNotificationsList: List = {
//   name: "Settings_Notification_NotificationCenter_ReceiveInformationNotifications",
//   entries: [
//     // {
//     //   label: "Settings_Notification_NotificationCenter_ReceiveInformationNotifications_ShowAll",
//     //   action: EEntryActions.CHECKBOX,
//     //   selected: true
//     // },
//     {
//       label: "Settings_Notification_NotificationCenter_ReceiveInformationNotifications_VoiceControl",
//       action: EEntryActions.CHECKBOX,
//       selected: true
//     },
//     {
//       label: "Settings_Notification_NotificationCenter_ReceiveInformationNotifications_DataConnection",
//       action: EEntryActions.CHECKBOX,
//       selected: true
//     }
//   ]
// }

// DEVICES SETTINGS ////////////////////////////////////////////////////////////

const settings_HomeLinkList: List = {
  name: "Settings_Options",
  icon: icon.homelink,
  entries: [
    { label: "Settings_HomeLink_ConfigureProfileInStandardMode", action: EEntryActions.NONE },
    { label: "Settings_HomeLink_ConfigureProfileInRepairKitMode", action: EEntryActions.NONE },
    { label: "Settings_HomeLink_DeleteAllProfile", action: EEntryActions.NONE }
  ]
}

const settings_HomeLinkList_Settings: List = {
  name: "Settings_Main_HomeLink",
  icon: icon.homelink,
  entries: [...settings_HomeLinkList.entries]
}

// LAUNCHER CONFIG SETTINGS ////////////////////////////////////////////////////

const Settings_Launcher: List = {
  name: "Settings_Options",
  entries: [
    {
      label: "Settings_Launcher_Context_Options_Adapt_Sorting",
      action: EEntryActions.LINK,
      link: ROUTES_MAIN.LAUNCHER_CONFIG
    }
  ]
}

// INFO TILE ///////////////////////////////////////////////////////////////////

const Settings_Info: List = {
  name: "Info",
  icon: icon.information_outline,
  entries: [
    {
      label: "Settings_Dataprivacy",
      action: EEntryActions.LINK,
      link: ROUTES_MAIN.INFO_DATAPRIVACY
    },
    {
      label: "Settings_Imprint",
      action: EEntryActions.LINK,
      link: ROUTES_MAIN.INFO_IMPRINT
    }
  ]
}

const listContents: {
  [index: string]: Readonly<List>
} = {
  Calendar_Settings_AddKalendaerList,
  Settings_AssistanceSystems_AdaptiveSpeedLimiterList,
  Settings_AssistanceSystems_DataExportList,
  Settings_AssistanceSystems_ExteriorLightsList,
  Settings_AssistanceSystems_HazardousConditionsList,
  Settings_AssistanceSystems_LaneChangeAssistList,
  Settings_AssistanceSystems_LaneDepartureWarningList,
  Settings_AssistanceSystems_NightViewAssistList,
  Settings_AssistanceSystems_ParkAssistList,
  Settings_AssistanceSystems_PorscheInnoDriveList,
  Settings_AssistanceSystems_TrafficSignRecognitionList,
  Settings_AssistanceSystems_WarnAndBrakeAssistList,
  settings_AssistanceSystemsList,
  settings_AudioList,
  settings_CalendarTileList,
  Settings_Car_Drive_IndividualDriveModeConfiguration,
  settings_CarAssist_Settings,
  settings_CarComfort_Settings,
  settings_CarDrive_Settings,
  settings_CarTrip_ConfigureTripPersonally,
  settings_CarTrip_ResetTripData,
  settings_CarTrip_Settings,
  settings_ChargingList,
  Settings_DeviceManager_BluetoothSettingsList,
  Settings_DeviceManager_WiFiSettings_ConfigureVehicleHotspotList,
  Settings_DeviceManager_WiFiSettingsList,
  settings_DeviceManagerList,
  Settings_Devices_ConnectionAssist,
  settings_DevicesList,
  Settings_Display_AdditionalInstrumentList,
  Settings_Display_CenterConsoleOperatingPanelList,
  Settings_Display_CentralDisplayList,
  Settings_Display_HUD_TrafficSignList,
  Settings_Display_HUDList,
  Settings_Display_HUDList_Layout,
  Settings_Display_InstrumentCluster_CustomisedViewList,
  Settings_Display_InstrumentCluster_PersonalSpeedLimitsList,
  Settings_Display_InstrumentCluster_TrafficSignRecognitionList,
  Settings_Display_InstrumentClusterList,
  Settings_Display_PassengerDisplayList,
  settings_DisplaysList,
  settings_HomeLinkList,
  settings_HomeLinkList_Settings,
  Settings_Info,
  Settings_Launcher,
  Settings_Main_SportChronoList,
  settings_MainList,
  settings_MainList_Passenger,
  Settings_Media_AdjustSortingOfSourcesList,
  settings_MediaList,
  Settings_Navigation_AlternativeRoutesList,
  Settings_Navigation_EditRouteList,
  Settings_Navigation_NavigationSettings_MapSettingsList,
  Settings_Navigation_NavigationSettings_TrafficInformationList,
  Settings_Navigation_NavigationSettingsList,
  Settings_Navigation_RouteOptionsList,
  settings_NavigationList,
  settings_NavigationList_Settings,
  settings_NewsTileList,
  Settings_Notification_NotificationCenter,
  Settings_Notification_NotificationCenter_ReceiveVehicleNotifcationList,
  Settings_NotificationCenter_CarNotificationsList,
  Settings_NotificationCenter_InfoNotificationsList,
  Settings_NotificationCenter_PhoneNotificationsList,
  settings_NotificationCenterList,
  settings_NotificationList,
  Settings_Phone_GeneralSettingsList,
  Settings_Phone_PhoneBookSettingsList,
  settings_PhoneList,
  settings_PhoneTileList,
  settings_PhoneTileList_Contacts,
  Settings_PrivacyAndPorscheConnect_ExternalAuthorisationsList,
  settings_PrivacyAndPorscheConnectList,
  settings_RadioList,
  settings_RangeDriveMode_Configuration,
  settings_SoftwareInformation_VersionInfoList,
  settings_SoftwareInformationList,
  settings_SoundList,
  Settings_System_DateAndTimeList,
  Settings_System_TimeAndTemperatureList,
  Settings_System_LanguageAndKeyboardList,
  Settings_System_UnitsList,
  settings_SystemList,
  settings_UpdatesList,
  Settings_Vehicle_AdditionalChassisSettingsList,
  Settings_Vehicle_LightAndVisibility_ExteriorList,
  Settings_Vehicle_LightAndVisibility_InteriorList,
  Settings_Vehicle_LightAndVisibility_ReversingList,
  Settings_Vehicle_LightAndVisibilityList,
  Settings_Vehicle_ShortcutButtonsList,
  Settings_Vehicle_TrunkLidAndTailgatesList,
  Settings_Vehicle_TyrePressureMonitoringList,
  Settings_Vehicle_VehicleLockingSystemsList,
  settings_VehicleList,
  settings_VehicleList_Settings,
  settings_VoiceControlList,
  settings_VolumeList,
  Settings_WeatherList,
  settings_WeatherTileList
}

export default listContents
