import object from "@pag/utils/object-tools"
import icon from "@pag/center/components/icon/icons"
import { Device, Service } from "./types"

const services: readonly Service[] = object.populate(
  "icon"
)(
  [ icon.cell_phone      ],
  [ icon.note            ],
  [ icon.message_outline ],
  [ icon.apple_carplay   ],
  [ icon.android_auto    ],
  [ icon.connect_app     ],
  [ icon.wlan            ],
  [ icon.bluetooth       ],
  [ icon.ipod            ]
)

const [
  phone,
  media,
  messages,
  carplay,
  android_auto,
  connect_app,
  hotspot,
  headset,
  ipod
] = services

const devices: readonly Device[] = object.populate(
    "name",                   "icon",          "services"
)(
  [ "John Smith's iPhone",    icon.cell_phone, [ phone, media, messages, carplay ]      ],
  [ "Maria Bianchi's iPhone", icon.cell_phone, [ phone, media, messages, carplay ]      ],
  [ "John Doe's Phone",       icon.cell_phone, [ phone, media, messages, android_auto ] ],
)

export default Object.freeze({
  services,
  devices,

  phone_service: phone,
  media_service: media,
  carplay_service: carplay,
  android_auto_service: android_auto,
  connect_app_service: connect_app,
  hotspot_service: hotspot,
  headset_service: headset,
  ipod_service: ipod

});

