import React, { CSSProperties, FunctionComponent, useState } from "react"
import ClassNames from "classnames"

import { IconType } from "@pag/center/components/icon/types"
import I18nLabel from "@pag/center/components/I18nLabel/I18nLabel"
import Icon from "@pag/center/components/icon/Icon"

import "./button.scss"

interface IButton {
  style?: CSSProperties
  labelText?: string
  icon?: IconType
  iconUrl?: string
  hastText?: boolean
  nonI18n?: string
  buttonId?: string
  className?: string
  disabled?: boolean
  disabledText?: string
  onClick?: () => void
}

const Button: FunctionComponent<IButton> = ({
  labelText = "",
  icon,
  iconUrl,
  hastText,
  nonI18n,
  buttonId,
  className,
  onClick,
  disabled,
  disabledText,
  style
}) => {
  const [pressed, setPressed] = useState(false)

  function animateClick() {
    setPressed(true)
    setTimeout(() => {
      setPressed(false)
      if (onClick) onClick()
    }, 300)
  }

  return (
    <div
      style={style}
      className={ClassNames(className, "button__host", { "state-pressed": pressed }, { "state-disabled": disabled })}
      onClick={() => {
        !disabled && animateClick()
      }}
    >
      {/* <div className="animated-bg--growing-circle" /> */}
      <div className="animated-bg--pressed" />
      <div className="button__fading">
        <div className="button__container">
          {icon && <Icon iconClass={ClassNames("button__icon", { "button--icon-only": !(nonI18n || labelText) })} iconType={icon} />}
          {iconUrl && <Icon iconClass={ClassNames("button__icon", { "button--icon-only": !(nonI18n || labelText) })} iconUrl={iconUrl} />}
          {(nonI18n || labelText) && (
            <I18nLabel
              text={disabled && disabledText ? disabledText : labelText}
              id={buttonId}
              className="button__text"
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default Button
